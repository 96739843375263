import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { CULTIVATION_FEATURES } from './mock';
import FeatureCell from '../../components/FeatureCell';

function CustomizedCultivation() {
  const [features, setFeatures] = useState(CULTIVATION_FEATURES);
  const [isShowedAll, setIsShowedAll] = useState(false);
  useEffect(() => {
    setIsShowedAll(features.length === CULTIVATION_FEATURES.length);
  }, [features]);
  return (
    <React.Fragment>
      <Header isShadowed activeLink="customized-cultivation" />
      <div className="content-wrap no-sides">
        <section>
          <div className="section-heading center black">
            <div className="text trimmed-width">
              <div className="image-wrap">
                <img
                  src="/images/take-first-step/leaf-icon.png"
                  srcSet="/images/take-first-step/leaf-icon@2x.png 2x,
             /images/take-first-step/leaf-icon@3x.png 3x"
                  alt="leaf"
                />
              </div>
              <h1>
                In order to see the true health of your cultivation center, you need to see the big
                picture as well as the details.
              </h1>
              {/* <button className="btn green">View Features</button> */}
            </div>
          </div>
        </section>
        <section className="customized-cultivation">
          <div className="inner">
            <div className="image-left">
              <img
                src="/images/customized/customized-cultivation-1.png"
                srcSet="/images/customized/customized-cultivation-1@2x.png 2x,
             /images/customized/customized-cultivation-1@3x.png 3x"
                alt="plants"
              />
              <p>
                From seed to sale, we offer a transparent view into the life of your cultivation
                operation. Our automated processes will turn your grow room into a seamless
                production, and allow you extreme visibility into each step of the process. And
                since your business is different from any other, we offer you a fully customized
                solution tailored to your precise needs.{' '}
              </p>
            </div>
            <div className="image-right">
              <p>
                Forget needing multiple software platforms and workarounds. We’re here to eliminate
                your pain points, not create them. We will take every piece of your operation into
                consideration as we build a program around your cultivation center, allowing you to
                capture the data that matters most to you and your customers.
              </p>
              <img
                src="/images/customized/customized-cultivation-2.png"
                srcSet="/images/customized/customized-cultivation-2@2x.png 2x,
             /images/customized/customized-cultivation-2@3x.png 3x"
                alt="plants"
              />
            </div>
          </div>
        </section>
        <div className="section-heading center black">
          <div className="text trimmed-width">
            <h1>
              Here are a few of the features you can expect, with complete customization for your
              business:
            </h1>
          </div>
        </div>
        <section>
          <div className="features-grid">
            {features.map((feature, featureIndex) => (
              <FeatureCell key={`feature-${featureIndex}`} {...feature} />
            ))}
          </div>
        </section>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default CustomizedCultivation;
