import React, { Fragment } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { ACCOUNTING_FEATURES } from '../../helpers/solutions-mock';
import FeatureCell from '../../components/FeatureCell';
import TakeFirstStep from '../../components/TakeFirstStep';
import PartnerLogos from '../../components/PartnerLogos';
import SolutionsSubnav from '../../components/SolutionsSubnav';

function Accounting() {
  return (
    <Fragment>
      <Header isShadowed activeLink="solutions" />
      <div className="content-wrap">
        <SolutionsSubnav />
        <section>
          <div className="section-heading solutions-section black">
            <div className="text">
              <h3>
                <img
                  src="/images/solutions-index/2.png"
                  srcSet="/images/solutions-index/2@2x.png 2x,
             /images/solutions-index/2@3x.png 3x"
                  alt="icon"
                />
                Accounting
              </h3>
              <h1>Whether you’re the do-it-yourself type or have an accountant on hand.</h1>
              <p>
                THSuite takes care of all of your accounting needs. Get day-to-day reporting on the
                value of your inventory, plus quarterly and yearly reporting for tax purposes. All
                you have to do is make the sales — we’ll crunch the numbers.
              </p>
              {/* <button className="btn green">View Features</button> */}
            </div>
            <div className="image">
              <img
                src="/images/solutions-pages/accounting/section-image.png"
                srcSet="/images/solutions-pages/accounting/section-image@2x.png 2x,
             /images/solutions-pages/accounting/section-image@3x.png 3x"
                alt="icon"
              />
            </div>
          </div>
        </section>
        <section>
          <div className="section-heading center black">
            <h1>Our Accounting Features:</h1>
          </div>
        </section>
        <div className="features-grid">
          {ACCOUNTING_FEATURES.map((feature, featureIndex) => (
            <FeatureCell key={`feature-${featureIndex}`} {...feature} />
          ))}
        </div>
      </div>
      <TakeFirstStep />
      <PartnerLogos />
      <Footer />
    </Fragment>
  );
}

export default Accounting;
