import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function Tos() {
  return (
    <React.Fragment>
      <Header isShadowed />
      <div className="content-wrap">
        <div className="section-heading centered left black separated">
          <div className="text">
            <h3>THSuite's</h3>
            <h1>Terms of Service</h1>
            <p>
              This Terms of Use Agreement governs your access to and use of THSuite.com and
              constitutes a binding agreement between THSUITE, LLC (“we”, “us”, or “our”) and each
              end user (“you” or “your”). Please read this Agreement carefully because by using or
              accessing THSuite.com, you agree to be bound by this Agreement. If you do not agree to
              the terms of this Agreement, do not use THSuite.com.
            </p>
          </div>
        </div>
        <div className="tos-text padded">
          1. We grant you permission to use THSuite.com, provided that: (a) you are a person over
          the age of 13; (b) you have the legal right and capacity to use THSuite.com in your
          jurisdiction; (c) you are located in a country that is not the subject of a U.S.
          Government embargo or has been designated by the U.S. Government as a “terrorist
          supporting” country; (d) you are not listed on any U.S. Government list of prohibited or
          restricted parties; (e) your use is solely for your commercial use; (f) you will not copy,
          reverse engineer, decompile, reproduce, disassemble or distribute any part of THSuite.com
          in any medium without our prior written consent; (g) you will not create any derivative
          works based on THSuite.com without our prior written consent; (h) you will not alter or
          modify any part of THSuite.com other than as may be reasonably necessary to use
          THSuite.com for their intended purpose; and (i) you will otherwise comply with the terms
          of this Agreement. We may change the terms of this Agreement from time to time with or
          without notice to you. Any new features added to THSuite or THSuite.com are subject to
          this Agreement. However, no change to this Agreement will apply to a dispute of which we
          had actual notice on the date of such change. We will notify you of any changes to this
          Agreement by posting them to THSuite.com and wherever this Agreement is found. You agree
          that you will periodically check THSuite.com for updates to this Agreement. You agree that
          you will be deemed to have notice of any modifications once we post them to THSuite.com
          and that your continued use of THSuite.com after such notice shall be deemed an acceptance
          of any changes. We reserve the right to discontinue THSuite and THSuite.com or to change
          the content of THSuite and THSuite.com in any way and at any time, with or without notice
          to you, without liability. We reserve the right to discontinue your use of THSuite and
          THSuite.com for any violation of this Agreement as determined by us in our sole
          discretion.
          <br />
          <br />
          2. You are solely responsible for your account with us so please make sure that your
          information is accurate, secure and under your control. In order to purchase products and
          services from us, you will have to create an account. You are not allowed to use another
          user’s account without the other user’s permission. When creating your account, you must
          provide accurate and complete information. If any of your information changes, you agree
          to update your registration information as soon as possible. You are solely responsible
          for the activity that occurs on your account, and you must keep your account password
          secure. You may change your password at any time by updating your account profile page. In
          addition, you agree to immediately notify us of any unauthorized use of your password or
          account or any other breach of security. We cannot and will not be liable for any loss or
          damage arising from your failure to comply with this paragraph. We reserve the right to
          terminate your access to all or part of THSuite and THSuite.com at any time in our sole
          discretion and without prior notice.
          <br />
          <br />
          3. You agree not to use or launch any automated system, including without limitation,
          “robots,” “spiders,” or similar technological devices or programs that access THSuite or
          THSuite.com in a manner that sends more request messages to THSuites’ or THSuite.coms’
          servers in a given period of time than a human can reasonably produce in the same period
          by using a conventional on-line web browser. Notwithstanding the foregoing, we grant the
          operators of public search engines permission to use spiders to copy materials from
          THSuite.com for the sole purpose of creating publicly available searchable indices of the
          materials, but not caches or archives of such materials. We reserve the right to revoke
          these exceptions either generally or in specific cases, in our sole discretion.
          <br />
          <br />
          4. When you provide us with your ideas, you grant to us a non-exclusive, transferable,
          sub-licensable, worldwide, perpetual, royalty-free license to use, store, distribute,
          reproduce, modify, adapt, perform (publicly or otherwise) and display (publicly or
          otherwise) such ideas in connection with THSuite and THsuite.com. You grant to us this
          license so that your ideas can be used for the purposes for which you submitted it and so
          that it may also be used to improve the quality, features and functionality of THSuite and
          THSuite.com. You represent and warrant that you have the right to grant the license of
          your ideas granted in this Agreement. We claim no ownership in your ideas and you are
          still free to use your ideas in any manner you choose.
          <br />
          <br />
          5. Be aware of third party links when you use THSuite and THSuite.com. We assume no
          responsibility for, the content, privacy policies, or practices of any third-party
          websites and products. By using THSuite and THSuite.com, you specifically release us from
          any and all liability arising from your use of any third-party website or product.
          <br />
          <br />
          6. Respect our intellectual property rights when you use THSuite and THSuite.com. The
          content on THSuite and THSuite.com including the text, graphics, and photos created by and
          for us (“THSuite Content”) and the trademarks, service marks and logos contained therein
          (“Marks”), are owned by or licensed to us, subject to copyright and other intellectual
          property rights under United States and foreign laws and international conventions.
          THSuite Content on THSuite and THSuite.com is provided to you AS IS for your information
          and personal use only and may not be used for any other purpose whatsoever without the
          prior written consent of us or as expressly provided herein.
          <br />
          <br />
          7. Respect our intellectual property rights and do not disrupt security for THSuite and
          THSuite.com. You agree to not use any of the THSuite Content other than expressly
          permitted herein. If you download or print a copy of the THSuite Content for personal or
          other non-infringing use, you must retain all copyright and other proprietary notices
          contained on such THSuite Content. You agree not to circumvent, disable or otherwise
          interfere with security related features of THSuite and THSuite.com or features that
          prevent or restrict use or copying of any THSuite Content or enforce limitations on use of
          THSuite and THSuite.com or the THSuite Content therein.
          <br />
          <br />
          8. YOU USE THSUITE.COM AT YOUR OWN RISK. WE ARE PROVIDING THSUITE.COM “AS IS” WITHOUT ANY
          EXPRESS OR IMPLIED WARRANTIES INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT
          GUARANTEE THAT THSUITE.COM WILL BE SAFE OR SECURE. WE ARE NOT RESPONSIBLE FOR THE ACTIONS,
          CONTENT, INFORMATION, OR DATA OF THIRD PARTIES, AND YOU RELEASE US, OUR DIRECTORS,
          OFFICERS, EMPLOYEES, AND AGENTS FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING
          OUT OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH THIRD PARTIES. WE
          WILL NOT BE LIABLE TO YOU FOR ANY LOST PROFITS OR OTHER CONSEQUENTIAL, SPECIAL, INDIRECT,
          OR INCIDENTAL DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THSUITE.COM EVEN IF
          WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY ARISING
          FROM YOUR USE OF THSUITE.COM WILL NOT EXCEED THE AMOUNT YOU HAVE PAID US IN THE NINETY
          DAYS IMMEDIATELY PRECEDING THE DATE ON WHICH YOUR CLAIM AROSE. APPLICABLE LAW MAY NOT
          ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
          THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH CASES, OUR LIABILITY WILL
          BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
          <br />
          <br />
          9. You agree to defend us, indemnify us and hold us harmless from and against all claims
          and expenses, including attorneys’ fees and costs, arising out of your use of THSuite.com
          and/or your breach or alleged breach of this Agreement. You agree that the provisions in
          this paragraph will survive any termination of your accounts with us or your use of
          THSuite and THSuite.com. For the purposes of the disclaimers and limitation of liability
          in the preceding paragraph and the indemnification in this paragraph you agree that all
          references to us includes our managers, members, affiliates, agents and employees.
          <br />
          <br />
          10. You agree that we reserve all rights not expressly granted to you in this Agreement.
          <br />
          <br />
          11. This Agreement shall be governed by and construed in accordance with the internal laws
          of the United States and the State of Illinois without regard to conflict of laws
          provisions. You agree not to commence or prosecute any action in connection with this
          Agreement other than in the state and federal courts located in Cook County, Illinois and
          you hereby consent to, and waive all defenses of lack of personal jurisdiction and forum
          non conveniens with respect to, venue and jurisdiction in the state and federal courts
          located in Cook County, Illinois. Neither you nor THSuite shall initiate and participate
          in any class action for claims covered under the Agreement.
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Tos;
