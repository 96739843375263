import React, { Fragment } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { CUSTOMIZED_REPORTING_FEATURES } from '../../helpers/solutions-mock';
import FeatureCell from '../../components/FeatureCell';
import TakeFirstStep from '../../components/TakeFirstStep';
import PartnerLogos from '../../components/PartnerLogos';
import SolutionsSubnav from '../../components/SolutionsSubnav';

function CustomizedReporting() {
  return (
    <Fragment>
      <Header isShadowed activeLink="solutions" />
      <div className="content-wrap">
        <SolutionsSubnav />
        <section>
          <div className="section-heading solutions-section black">
            <div className="text">
              <h3>
                <img
                  src="/images/solutions-index/4.png"
                  srcSet="/images/solutions-index/4@2x.png 2x,
             /images/solutions-index/4@3x.png 3x"
                  alt="icon"
                />
                Customized Reporting
              </h3>
              <h1>Reporting is the heart and soul of THSuite.</h1>
              <p>
                Offering reporting integration with your state’s API, strategic business reports and
                offline reporting in the event of internet failure. Our unique customized reporting
                will help you know exactly what’s going on within your business and increase
                opportunities for sales.
              </p>
              {/* <button className="btn green">View Features</button> */}
            </div>
            <div className="image">
              <img
                src="/images/solutions-pages/customized-reporting/section-image.png"
                srcSet="/images/solutions-pages/customized-reporting/section-image@2x.png 2x,
             /images/solutions-pages/customized-reporting/section-image@3x.png 3x"
                alt="icon"
              />
            </div>
          </div>
        </section>
        <section>
          <div className="section-heading center black">
            <h1>Our Customized Reporting Features:</h1>
          </div>
        </section>
        <div className="features-grid">
          {CUSTOMIZED_REPORTING_FEATURES.map((feature, featureIndex) => (
            <FeatureCell key={`feature-${featureIndex}`} {...feature} />
          ))}
        </div>
      </div>
      <TakeFirstStep />
      <PartnerLogos />
      <Footer />
    </Fragment>
  );
}

export default CustomizedReporting;
