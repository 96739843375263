export const CULTIVATION_FEATURES = [
  {
    title: 'Full State Compliance API Integration',
    imageUrl: '/images/customized/1.png',
    srcSet: '/images/customized/1@2x.png 2x, /images/customized/1@3x.png 3x',
    description:
      'Easily connect with any State Compliance software, including the Metrc State Traceability API, and automatically report every plant batch, harvest, package and sale. All state required information is collected and reported, so you can sit back and know it’s all being taken care of. Compliance fulfilled.'
  },
  {
    title: 'Full Plant Tracking    ',
    imageUrl: '/images/customized/2.png',
    srcSet: '/images/customized/2@2x.png 2x, /images/customized/2@3x.png 3x',
    description:
      'Scan and track every plant through the entire cultivation lifecycle. Know its precise location from greenhouse to packaging. Collect data on what you’re growing and review trends over time.      '
  },
  {
    title: 'Package audit    ',
    imageUrl: '/images/customized/3.png',
    srcSet: '/images/customized/3@2x.png 2x, /images/customized/3@3x.png 3x',
    description:
      'Trace each Package Label back to its harvest batch, plant batch or even plant tag. Know the precise dates and processes that touched every product you put out to market.    '
  },
  {
    title: 'Online Order Widget    ',
    imageUrl: '/images/customized/4.png',
    srcSet: '/images/customized/4@2x.png 2x, /images/customized/4@3x.png 3x',
    description:
      'Expedite the ordering process with our online ordering widget that can be placed on your website and is tied into your inventory. Customers can see what you have available and as they purchase, have your inventory kept up to date in real time. Make transactions as easy as the push of a button.    '
  },
  {
    title: 'CRM',
    imageUrl: '/images/customized/5.png',
    srcSet: '/images/customized/5@2x.png 2x, /images/customized/5@3x.png 3x',
    description:
      'Keep track of your customers, process orders, and note touchpoints by your sales team. Stay on top of sales goals and the sales funnel with our custom CRM.    '
  },
  {
    title: 'Quickbooks Integration    ',
    imageUrl: '/images/customized/6.png',
    srcSet: '/images/customized/6@2x.png 2x, /images/customized/6@3x.png 3x',
    description:
      'We’ll connect you right to the Quickbooks API, allowing you to continuously push your key metrics to your accounting software while you’re busy running your business. From sales to plant costs, you can easily and effectively track from the seed to the sale. Custom accounting solutions are also available.    '
  },
  {
    title: 'Waste Management Tracking & Reporting    ',
    imageUrl: '/images/customized/7.png',
    srcSet: '/images/customized/7@2x.png 2x, /images/customized/7@3x.png 3x',
    description:
      'Keep tabs on every bit of waste, including water loss and plant matter. Gain greater insight into your grow operation by knowing precisely what is being reported as waste.    '
  },
  {
    title: 'Trimmer Activity    ',
    imageUrl: '/images/customized/8.png',
    srcSet: '/images/customized/8@2x.png 2x, /images/customized/8@3x.png 3x',
    description:
      'Increase your visibility into your staffing and production needs by tracking the number of plants trimmed per hour, recording waste and increasing efficiency.    '
  },
  {
    title: 'Task Management    ',
    imageUrl: '/images/customized/9.png',
    srcSet: '/images/customized/9@2x.png 2x, /images/customized/9@3x.png 3x',
    description:
      'From equipment tasks to cleaning tasks, there are numerous processes taking place in your cultivation center each day. Assign tasks, know what’s getting done, and turn your business into a well-oiled machine. Custom reports provide greater visibility into average completion times to better understand the efficiency of your day-to-day operations.'
  }
];
