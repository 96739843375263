import React from 'react';
import { Link } from 'react-router-dom';

function SolutionCell({ title, imageUrl, description, linkUrl, srcSet, noLink }) {
  return (
    <div className={`solution-cell ${noLink ? 'partner-cell' : ''}`}>
      <img src={imageUrl} alt="" srcSet={srcSet} />
      {!!title && <h3 className="title">{title}</h3>}
      {!!description && <p className="description">{description}</p>}
      {!noLink && (
        <Link to={`${linkUrl}`} className="btn green">
          Learn More
        </Link>
      )}
    </div>
  );
}

export function SolutionLinkCell({ title, imageUrl, externalUrl, description, srcSet }) {
  return (
    <a
      href={externalUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="solution-cell partner-cell"
    >
      <img src={imageUrl} alt="" srcSet={srcSet} />
      {!!title && <h3 className="title">{title}</h3>}
      {!!description && <p className="description">{description}</p>}
    </a>
  );
}

export default SolutionCell;
