import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { CUSTOMER_SUPPORT_FEATURES } from '../../helpers/solutions-mock';
import FeatureCell from '../../components/FeatureCell';
import TakeFirstStep from '../../components/TakeFirstStep';
import PartnerLogos from '../../components/PartnerLogos';
import SolutionsSubnav from '../../components/SolutionsSubnav';

function CustomerSupport() {
  return (
    <Fragment>
      <Header isShadowed activeLink="solutions" />
      <div className="content-wrap">
        <SolutionsSubnav />
        <section>
          <div className="section-heading solutions-section black">
            <div className="text">
              <h3>
                <img
                  src="/images/solutions-index/8.png"
                  srcSet="/images/solutions-index/8@2x.png 2x,
             /images/solutions-index/8@3x.png 3x"
                  alt="icon"
                />
                Customer Support
              </h3>
              <h1>Call us or shoot over an email.</h1>
              <p>
                We know that the unexpected doesn’t happen on a schedule. So we’re around, whether
                it’s two in the morning or two in the afternoon. We’ll get your questions answered,
                solve your problems and keep your system running smoothly so you can get back to
                business.
              </p>
              {/* <button className="btn green">View Features</button> */}
            </div>
            <div className="image">
              <img
                src="/images/solutions-pages/customer-support/section-image.png"
                srcSet="/images/solutions-pages/customer-support/section-image@2x.png 2x,
             /images/solutions-pages/customer-support/section-image@3x.png 3x"
                alt="icon"
              />
            </div>
          </div>
        </section>
        <section>
          <div className="section-heading center black">
            <h1>Our Customer Support Features:</h1>
          </div>
        </section>
        <div className="features-grid">
          {CUSTOMER_SUPPORT_FEATURES.map((feature, featureIndex) => (
            <FeatureCell key={`feature-${featureIndex}`} {...feature} />
          ))}
        </div>
        <div className="center-button">
          <Link className="btn primary" to="/customer-service">
            Customer Service
          </Link>
        </div>
      </div>
      <TakeFirstStep />
      <PartnerLogos />
      <Footer />
    </Fragment>
  );
}

export default CustomerSupport;
