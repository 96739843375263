import React, { useContext } from 'react';
import { Context as ModalContext } from './ModalContext';
import { Link } from 'react-router-dom';

function TakeFirstStep() {
  const { isVisible, setIsVisible } = useContext(ModalContext);
  return (
    <section className="take-first-step">
      <div className="section-heading center black heading-column">
        <h1>Take The First Step</h1>
        <Link className="btn primary" to="/get-started">
          Book a Demo
        </Link>
      </div>
      <div className="img">
        <img
          src="/images/take-first-step/screen-idea-03.png"
          srcSet="/images/take-first-step/screen-idea-03@2x.png 2x,
             /images/take-first-step/screen-idea-03@3x.png 3x"
          alt="idea"
        />
      </div>
      <div className="text">
        <p>
          THSuite is a unique and powerful seed to the sale ERP solution. See how <br />
          THSuite can help you with your business:
        </p>

        <Link className="btn primary" to="/get-started">
          Get Started
        </Link>
      </div>
    </section>
  );
}

export default TakeFirstStep;
