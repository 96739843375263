import React from 'react';

function Input({ label, placeholder, type = 'text', rows, onChange }) {
  if (!!rows) {
    return (
      <div className="input-wrap">
        <label>{label}</label>
        <textarea
          placeholder={placeholder}
          rows={rows}
          onChange={e => {
            onChange && onChange(e.target.value);
          }}
        />
      </div>
    );
  }
  return (
    <div className="input-wrap">
      <label>{label}</label>
      <input
        type={type}
        placeholder={placeholder}
        onChange={e => {
          onChange && onChange(e.target.value);
        }}
      />
    </div>
  );
}

export default Input;
