import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const slogans = [
  'The Cannabis Industry',
  'Dispensary & Delivery',
  'Inventory Management',
  'THSuite'
];

function Hero({ children }) {
  const [slogan, setSlogan] = useState(slogans[0]);
  const [tabVisibility, settabVisibility] = useState('visible');
  const [isAnimated, setIsAnimated] = useState(true);
  const handler = s => settabVisibility(s);
  const timeout = s => setSlogan(s ? s : slogans[0]);
  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      handler(document.visibilityState);
    });
    return () => {
      document.removeEventListener('visibilitychange', () => {
        handler(document.visibilityState);
      });
    };
  }, []);
  useEffect(() => {
    setIsAnimated(true);
    setTimeout(() => {
      const s = slogans[slogans.indexOf(slogan) + 1];
      timeout(s);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, [slogan]);
  useEffect(() => {
    if (tabVisibility === 'hidden') {
      setIsAnimated(false);
      clearTimeout(timeout);
    }
  }, [tabVisibility]);
  return (
    <div className="hero">
      {children}
      <div className="section-heading left black">
        <div className="text">
          <h3>
            Feel the power of <b className={classNames({ animated: isAnimated })}>{slogan}</b>
          </h3>
          <h1>Custom Point of Sale Software</h1>
          <p>
            Your cannabis point-of-sale system should hold nothing back. Not only should you expect
            security, compliance integration and simplicity, it should help you grow your business,
            too.{' '}
          </p>
          <Link className="btn primary" to="/get-started">
            Get Started
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Hero;
