import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './sass/main.scss';
import Home from './pages/Home/Home';
import Solutions from './pages/Solutions/Solutions';
import Administration from './pages/Solutions/Administration';
import Accounting from './pages/Solutions/Accounting';
import ComplianceIntegration from './pages/Solutions/ComplianceIntegration';
import CustomizedReporting from './pages/Solutions/CustomizedReporting';
import InventoryManagement from './pages/Solutions/InventoryManagement';
import POSSystem from './pages/Solutions/POSSystem';
import Logistics from './pages/Solutions/Logistics';
import CustomerSupport from './pages/Solutions/CustomerSupport';
import ScrollToTop from './ScrollToTop';
import CustomizedCultivation from './pages/Cultivation/CustomizedCultivation';
import Partners from './pages/Partners/Partners';
import Privacy from './pages/Tos/Privacy';
import Tos from './pages/Tos/Tos';
import CustomerService from './pages/Tos/CustomerService';
import ModalContext from './components/ModalContext';
import GetStartedPage from './components/GetStartedPage';
import DataSecurityIncident from './pages/Tos/DataSecurityIncident';

function App() {
  return (
    <Router>
      <ModalContext>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/solutions" component={Solutions} />
          <Route exact path="/solutions/administration" component={Administration} />
          <Route exact path="/solutions/accounting" component={Accounting} />
          <Route exact path="/solutions/compliance-integration" component={ComplianceIntegration} />
          <Route exact path="/solutions/customized-reporting" component={CustomizedReporting} />
          <Route exact path="/solutions/inventory-management" component={InventoryManagement} />
          <Route exact path="/solutions/pos-system" component={POSSystem} />
          <Route exact path="/solutions/logistics" component={Logistics} />
          <Route exact path="/solutions/customer-support" component={CustomerSupport} />

          <Route exact path="/customized-cultivation" component={CustomizedCultivation} />

          <Route exact path="/partners" component={Partners} />
          <Route exact path="/privacy-policy" component={Privacy} />
          <Route exact path="/terms-of-service" component={Tos} />
          <Route exact path="/customer-service" component={CustomerService} />
          <Route exact path="/get-started" component={GetStartedPage} />

          <Route exact path="/data-security-incident" component={DataSecurityIncident} />

          <Redirect to="/" />

        </Switch>
      </ModalContext>
    </Router>
  );
}

export default App;
