import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function Privacy() {
  return (
    <React.Fragment>
      <Header isShadowed />
      <div className="content-wrap">
        <div className="section-heading centered left black separated">
          <div className="text">
            <h3>THSuite's</h3>
            <h1>Privacy Policy</h1>
            <p>
              This Privacy Policy describes how and when THSuite LLC (“we”, “us”, or “our”)
              collects, uses and shares the information of each end user (“you” or “your”) who
              visits THSuite.com. We recognize that our customers, visitors and users (known
              collectively or individually as “Users”) value their privacy. We want you to read this
              Policy carefully prior to using or accessing THSuite.com because we want you to
              understand how we respect your privacy rights through this Policy. You are bound by
              this Policy if you access or use THSuite.com. If you do not agree to the terms of this
              Policy, do not access THSuite.com. Even when we update THSuite.com, your use of
              THSuite.com remains subject to the terms of this Privacy Policy and our Terms of Use
              Agreement.
            </p>
          </div>
        </div>
        <div className="tos-text">
          <p>1. Information Collection and Use General</p>
          We collect information about you when you use THSuite, when you visit THSuite.com, and any
          time you provide information to us so that we can provide a quality product and service to
          you. To purchase products and services from us, you will need to provide us with certain
          personally identifying information (“Personally Identifying Information”) to create an
          account with us. When you use THSuite and THSuite.com, we collect
          non-personally-identifying information (“Non-Personal Information”) about your use. This
          information may include your IP address, cookie information, browser information, page
          visitation history, crash diagnostic reports, device information, computer information,
          usage pattern information, operating system information, location information, timestamp
          information, metadata and any other anonymous information about the nature and frequency
          of your use of THSuite and THSuite.com. We use this information to make THSuite and
          THSuite.com better for your use.
          <p>Registration and Orders</p>
          To purchase products and services from us, you will need to create an account. To create
          an account, you will need to provide Personally Identifiable Information to us including
          your user name, email address and password. We may use the information that you provide to
          contact you about our policies, your account, your orders, and any updates or changes to
          THSuite. We share this information with our parent companies and affiliates for the same
          purposes. In order to purchase a product or service from us through THSuite or
          THSuite.com, you may need to provide other Personally Identifiable Information to us
          including your credit card number, expiration date, security code and billing address to
          fill your orders.
          <p>Third Party Websites and Products</p>
          Through THSuite.com, you may have the opportunity to access or interact with third party
          websites and products. Consequently, your Personally Identifiable Information may be
          shared with those third-party websites and products and those third-party websites and
          products may share your Personally Identifiable Information with us. If you click on a
          link to a third-party website or product, you will go to the third-party website or
          product that you selected. If you choose to provide your information directly to
          third-party websites and products, your information will be governed by the policies of
          those third-party websites and products.
          <br /> <br />
          Please remember that we do not have any control over third party websites and products.
          Those other websites and products may place their own cookies or other files on your
          mobile device or computer, solicit Personally Identifiable Information from you and follow
          different rules regarding the use of the information that you submit to them. We cannot
          control the activities of third parties, we have no responsibility for any use of your
          Personally Identifiable Information by such third parties, and we cannot guarantee that
          they will adhere to any privacy practices, including the ones we follow. We encourage you
          to read the privacy policies, statements and settings of third party websites and products
          prior to your interaction with them.
          <p>Children Under 13</p>
          We are committed to protecting the privacy of children. We do not knowingly or
          intentionally collect Personally Identifiable Information from children under the age of
          13.
          <p>2. Information Sharing and Disclosure</p>
          We do not rent, trade, or share Personally Identifiable Information with third parties,
          outside of our family of companies, except to provide products or services you have
          requested, when we have your permission, or under the following circumstances: <br />
          <br />
          • We may use third parties such as a credit card processing company to bill you for
          products and services sold through THSuite and THSuite.com and an email service provider
          to send out emails on our behalf. Please know that when you purchase or request a product
          or service from us and a third party is used by us, we only share the amount of Personally
          Identifiable Information as necessary for the third party to provide that product or
          service. We have agreements from the third parties that we use to provide products and
          services that you request that prohibit them from using your Personally Identifiable
          Information for any purpose other than providing the product or service that you
          requested.
          <br />
          <br />
          • We provide Non-Personal Information to third parties, including analytics companies and
          business partners, to help us understand usage patterns, to create new features,
          promotions, functionality, services and for marketing purposes. We allow third parties,
          including analytics companies and business partners, to collect Non-Personal Information
          from THSuite and THSuite.com for us for those same purposes. We may make Non-Personal
          Information publicly available in an aggregated statistical form.
          <br />
          <br />
          • We reserve the right to disclose Personally Identifiable Information and Non-Personal
          Information as required by law and when we believe that disclosure is necessary to protect
          our rights or comply with a judicial proceeding or legal process served upon us.
          <br />
          <br />
          • We may share Personally Identifiable Information and Non-Personal Information with third
          parties if we believe it is necessary in order to investigate, prevent, or take action
          regarding illegal activities, suspected fraud, situations involving potential threats to
          the physical safety of any person, violations of our Terms of Use, or as otherwise
          required by law.
          <br />
          <br />
          • If we go through a business transaction, such as a merger, acquisition by another
          company, or sale of all or a substantial portion of our assets, Personally Identifiable
          Information and Non-Personal Information will likely be among the assets transferred. You
          will be notified via email, sent to the primary email address specified in your account,
          if ownership or control of your Personally Identifiable Information is transferred to
          another company in a transaction where a majority of the company’s assets are transferred
          to that third-party company.
          <br />
          <br />
          <p>3. Cookies and Web Beacons</p>
          To improve the quality of your use of THSuite.com, we use “cookie” technology to collect
          additional usage data. A cookie is a small text file that is stored on a User’s computer
          or device for record-keeping purposes. We may use cookies in connection with your use of
          THSuite.com and link the information we store in cookies to any Personally Identifiable
          Information.
          <br />
          <br />
          We use both session ID cookies and persistent cookies. A session ID cookie enhances your
          ability to navigate THSuite.com and expires when you close your browser. A persistent
          cookie remains on your hard drive for an extended period of time. You can remove
          persistent cookies by following directions provided in your Internet browser’s “Help”
          file. Persistent cookies are set to store your passwords, so you don’t have to enter your
          password more than once.
          <br />
          <br />
          Persistent cookies may also enable us to track and target the interests of our Users to
          enhance the experience, customize features and create new products and websites.
          <br />
          <br />
          If you reject cookies, you may still use THSuite.com, but your ability to use certain
          features, such as the shopping cart and design scoring pages, will be limited.
          <br />
          <br />
          We utilize tracking technology to understand usage patterns, to create new features,
          promotions, functionality, services and for marketing purposes. However, none of the
          information collected via tracking technology is Personally Identifiable Information.
          <br />
          <br />
          The use of cookies by any of our business partners, affiliates, tracking utility company
          or service providers is not covered by our Privacy Policy. We do not have access or
          control over these cookies. Our business partners, affiliates, tracking utility company
          and service providers may use session ID cookies to make it easier for you to use and
          navigate certain features, in order for you to use the shopping cart and similar features.
          <br />
          <br />
          We use web beacons in connection with e-mails sent to Users to let us know whether and
          when the email was opened. No Personally Identifiable Information is transmitted through
          web beacons.
          <br />
          <br />
          <p>4. Choice/Opt-Out</p>
          You can opt-out of our use of cookies by following directions provided in your Internet
          browser’s “Help” file on your computer and your device.
          <p>5. Confidentiality and Security</p>
          We provide safeguards to protect the information that we collect and maintain. We follow
          generally accepted industry standards to protect the Personally Identifiable Information
          submitted to us, both during transmission and once we receive it. No method of
          transmission over the Internet, or method of electronic storage is 100% secure, however.
          Therefore, while we strive to use commercially acceptable means to protect your Personally
          Identifiable Information, we cannot guarantee its absolute security. If you have any
          questions about security on THSuite or THSuite.com, you can send us an email at{' '}
          <a href="mailto:privacy@thsuite.com">privacy@thsuite.com</a>.<br />
          <br />
          We limit access to Personally Identifiable Information about you to employees who we
          believe reasonably need to come into contact with Personally Identifiable Information to
          provide products or services to you or in order to do their jobs..
          <br />
          <br />
          The security of your Personally Identifiable Information is important to us. When you
          enter sensitive information on our registration or order forms, we encrypt that
          information using secure socket layer technology (SSL).
          <br />
          <br />
          <p>6. Your Ability to Edit and Delete Your Account Information</p>
          If your Personally Identifiable Information changes, or if you no longer desire to
          maintain an account with us, you may correct, update, amend, or deactivate it by emailing
          our customer support at info@thsuite.com. We will make all efforts to respond to your
          request to access within 30 days.
          <br />
          <br />
          <p>7. Changes and Updates to this Privacy Policy</p>
          We may update our Privacy Policy at any time in our sole discretion and without prior
          notice. If we change our Privacy Policy, we will post those changes to this statement
          anywhere that it is found and may notify you in other ways that we deem appropriate. If we
          make significant changes in the way we treat Personally Identifiable Information, we may
          send a notice to the primary email address specified in your account or place a notice on
          our home page indicating that our Privacy Policy has changed or both. We reserve the right
          to modify our Privacy Policy at any time, so please review it frequently. Your continued
          use of THSuite and THSuite.com after any updates to this Policy have been made constitutes
          your acceptance of those updates.
          <p>8. Contact Information</p>
          If you have any questions or comments about this Privacy Policy, you can contact us at:
          THSuite, 3 Grant Sq #233, Hindsdale, IL 60521-3351{' '}
          <a href="mailto:info@thsuite.com">info@thsuite.com</a>.
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Privacy;
