import React from 'react';
import Features from './Features';
import Header from '../../components/Header';
import Hero from './Hero';
import Footer from '../../components/Footer';
import PartnerLogos from '../../components/PartnerLogos';
import { Link } from 'react-router-dom';
import Testimonials from './Testimonials';

function Home() {
  return (
    <React.Fragment>
      <Header />
      <Hero />
      <Features />
      <section className="ultimate-solution">
        <div className="section-heading left black">
          <div className="image">
            <img
              alt="icon"
              src="/images/backgrounds/screen-img-01.png"
              srcSet="/images/backgrounds/screen-img-01@2x.png 2x,
             /images/backgrounds/screen-img-01@3x.png 3x"
              className="screen-img-01"
            />
          </div>
          <div className="text">
            <h3>The ultimate solution</h3>
            <h1>Your cannabis point-of-sale system should hold nothing back.</h1>
            <p>
              Not only should you expect security, compliance integration and simplicity, it should
              help you grow your business, too. We dug into the nightmares of manual data entry and
              heard countless tales of time-consuming setup, only to discover the system wouldn’t
              produce reports as needed or make compliance any easier.
            </p>
            <Link className="btn transparent" to="/solutions/pos-system">
              Learn More <div className="chevron-right"></div>
            </Link>
          </div>
        </div>
      </section>

      <section className="explore">
        <div className="section-heading right white">
          <div className="text">
            <h3>Explore the Possibilities</h3>
            <h1>Take The First Step</h1>
            <p>
              THSuite is a unique and powerful Point of Sale ERP solution. See how THSuite can help
              you with your business:
            </p>
            <Link className="btn primary" to="/get-started">
              Get Started
            </Link>
          </div>
          <div className="image">
            <img
              alt="icon"
              src="/images/backgrounds/screen-img-02.png"
              srcSet="/images/backgrounds/screen-img-02@2x.png 2x,
             /images/backgrounds/screen-img-02@3x.png 3x"
              className="screen-img-02"
            />
          </div>
        </div>
      </section>

      <section className="feedback">
        <div className="section-heading center white">
          <div className="text">
            <h1>What Customers Are Saying</h1>
          </div>
        </div>
      </section>

      <Testimonials />

      <PartnerLogos />
      <Footer />
    </React.Fragment>
  );
}

export default Home;
