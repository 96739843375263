import React from 'react';

function SuccessSent() {
  return (
    <div className="section-heading center black">
      <div className="text trimmed-width">
        <img
          src="/images/take-first-step/leaf-icon.png"
          srcSet="/images/take-first-step/leaf-icon@2x.png 2x,
       /images/take-first-step/leaf-icon@3x.png 3x"
          alt="leaf"
        />
        <h1>Thank you, your submission is on its way!</h1>
        <p>
          Keep your phone handy - someone from our team will be reaching out via phone or email in
          the next few hours.
        </p>
      </div>
    </div>
  );
}

export default SuccessSent;
