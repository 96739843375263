import React from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

function Testimonials() {
  const windowWidth = window.innerWidth;
  return (
    <section className="testimonials">
      <div className="testimonials-wrap">
        <CarouselProvider
          naturalSlideHeight={windowWidth >= 640 ? 520 : 900}
          naturalSlideWidth={550}
          totalSlides={6}
          visibleSlides={windowWidth >= 640 ? 2 : 1}
          isPlaying={windowWidth >= 640}
          infinite
          dragEnabled={false}
        >
          <Slider className="slider">
            <Slide index={0}>
              <div className="slider-item">
                <div className="circle">
                  <img
                    alt="icon"
                    src="/images/leaf-circle-icon.png"
                    srcSet="/images/leaf-circle-icon@2x.png 2x,
       /images/leaf-circle-icon@3x.png 3x"
                    className="leaf-circle-icon"
                  />
                </div>
                <h2>
                  “I can’t say enough good things <br />
                  about THSuite and their staff
                </h2>
                <p>
                  Our processes have become more streamlined and auditable, allowing us to provide a
                  better patient experience while remaining productive and efficient as a business.
                  The ongoing support and customization, whether large or small is a major
                  differentiator between other companies we researched. I would highly recommend
                  THSuite to anyone in the cannabis industry. ”
                </p>
                <h3>- Daniel Korpon, CTO, Peninsula Alternative Health</h3>
              </div>
            </Slide>
            <Slide index={1}>
              <div className="slider-item">
                <div className="circle">
                  <img
                    alt="icon"
                    src="/images/leaf-circle-icon.png"
                    srcSet="/images/leaf-circle-icon@2x.png 2x,
       /images/leaf-circle-icon@3x.png 3x"
                    className="leaf-circle-icon"
                  />
                </div>
                <h2>“THSuite has improved our compliance</h2>
                <p>
                  and inventory monitoring with a streamlined tracking system, integration with
                  other platforms and exceptional customer service. Their ability to be agile and
                  adapt their system to our needs has given us great confidence in their customer
                  service and genuine effort to help our business operate smoothly and effectively.”
                </p>
                <h3>- Jordan Baker, GM, Kannavis</h3>
              </div>
            </Slide>
            <Slide index={2}>
              <div className="slider-item">
                <div className="circle">
                  <img
                    alt="icon"
                    src="/images/leaf-circle-icon.png"
                    srcSet="/images/leaf-circle-icon@2x.png 2x,
       /images/leaf-circle-icon@3x.png 3x"
                    className="leaf-circle-icon"
                  />
                </div>
                <h2>“THSuite has been a wonderful extension</h2>
                <p>
                  to our team here at Bloom Medicinals. Their development team is constantly
                  customizing the platform for us so that we are able to continue running our
                  business exactly the way we want, while ensuring we are compliant with the State
                  at all times. Their excellent customer service team always answers the phone or
                  calls back right away so we're never left frustrated wondering if someone will
                  call us back. I would highly recommend THSuite to anyone opening a new dispensary
                  or in need of converting to a new POS.”
                </p>
                <h3>- William Hollander, COO, Bloom Medicinals</h3>
              </div>
            </Slide>
            <Slide index={3}>
              <div className="slider-item">
                <div className="circle">
                  <img
                    alt="icon"
                    src="/images/leaf-circle-icon.png"
                    srcSet="/images/leaf-circle-icon@2x.png 2x,
       /images/leaf-circle-icon@3x.png 3x"
                    className="leaf-circle-icon"
                  />
                </div>
                <h2>“THSuite's service is lightyears ahead</h2>
                <p>
                  of our last provider. They made the on-boarding process very quick and easy for
                  us, being there every step of the way to ensure we were prepared and state
                  compliant for launch day. The platform is feature filled and started saving us
                  time/money around our dispensary from day one. Their dedicated team is always
                  quick to answer any questions that aren't already answered in their robust online
                  customer service database. They are exactly what we were looking for in our POS /
                  Inventory / ERP solution!”
                </p>
                <h3>- Austin Smith, Manager / IT Coordinator, Kompo</h3>
              </div>
            </Slide>
            <Slide index={4}>
              <div className="slider-item">
                <div className="circle">
                  <img
                    alt="icon"
                    src="/images/leaf-circle-icon.png"
                    srcSet="/images/leaf-circle-icon@2x.png 2x,
       /images/leaf-circle-icon@3x.png 3x"
                    className="leaf-circle-icon"
                  />
                </div>
                <h2>
                  “I can’t say enough good things <br />
                  about THSuite and their staff
                </h2>
                <p>
                  Our processes have become more streamlined and auditable, allowing us to provide a
                  better patient experience while remaining productive and efficient as a business.
                  The ongoing support and customization, whether large or small is a major
                  differentiator between other companies we researched. I would highly recommend
                  THSuite to anyone in the cannabis industry. ”
                </p>
                <h3>- Daniel Korpon, CTO, Peninsula Alternative Health</h3>
              </div>
            </Slide>
            <Slide index={5}>
              <div className="slider-item">
                <div className="circle">
                  <img
                    alt="icon"
                    src="/images/leaf-circle-icon.png"
                    srcSet="/images/leaf-circle-icon@2x.png 2x,
       /images/leaf-circle-icon@3x.png 3x"
                    className="leaf-circle-icon"
                  />
                </div>
                <h2>“THSuite has improved our compliance</h2>
                <p>
                  and inventory monitoring with a streamlined tracking system, integration with
                  other platforms and exceptional customer service. Their ability to be agile and
                  adapt their system to our needs has given us great confidence in their customer
                  service and genuine effort to help our business operate smoothly and effectively.”
                </p>
                <h3>- Jordan Baker, GM, Kannavis</h3>
              </div>
            </Slide>
            <Slide index={6}>
              <div className="slider-item">
                <div className="circle">
                  <img
                    alt="icon"
                    src="/images/leaf-circle-icon.png"
                    srcSet="/images/leaf-circle-icon@2x.png 2x,
       /images/leaf-circle-icon@3x.png 3x"
                    className="leaf-circle-icon"
                  />
                </div>
                <h2>“THSuite has been a wonderful extension</h2>
                <p>
                  to our team here at Bloom Medicinals. Their development team is constantly
                  customizing the platform for us so that we are able to continue running our
                  business exactly the way we want, while ensuring we are compliant with the State
                  at all times. Their excellent customer service team always answers the phone or
                  calls back right away so we're never left frustrated wondering if someone will
                  call us back. I would highly recommend THSuite to anyone opening a new dispensary
                  or in need of converting to a new POS.”
                </p>
                <h3>- William Hollander, COO, Bloom Medicinals</h3>
              </div>
            </Slide>
            <Slide index={7}>
              <div className="slider-item">
                <div className="circle">
                  <img
                    alt="icon"
                    src="/images/leaf-circle-icon.png"
                    srcSet="/images/leaf-circle-icon@2x.png 2x,
       /images/leaf-circle-icon@3x.png 3x"
                    className="leaf-circle-icon"
                  />
                </div>
                <h2>“THSuite's service is lightyears ahead</h2>
                <p>
                  of our last provider. They made the on-boarding process very quick and easy for
                  us, being there every step of the way to ensure we were prepared and state
                  compliant for launch day. The platform is feature filled and started saving us
                  time/money around our dispensary from day one. Their dedicated team is always
                  quick to answer any questions that aren't already answered in their robust online
                  customer service database. They are exactly what we were looking for in our POS /
                  Inventory / ERP solution!”
                </p>
                <h3>- Austin Smith, Manager / IT Coordinator, Kompo</h3>
              </div>
            </Slide>
          </Slider>
        </CarouselProvider>
      </div>
    </section>
  );
}

export default Testimonials;
