import React, { useState } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CustomerImages from './CustomerImages';
import Input from '../../components/Input';
import { useReducer } from 'react';
import axios from 'axios';
import SuccessSent from '../../components/SuccessSent';

function CustomerService() {
  const [fields, setFields] = useReducer((o, n) => ({ ...o, ...n }), {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    message: ''
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const handleSubmit = () => {

    //Require all fields
    const requiredFields = [
      'firstName',
      'lastName',
      'phoneNumber',
      'companyName',
      'email',
      'message'
    ];
    const requiredMessage = {
      'firstName': "Please enter your first name",
      'lastName': "Please enter your last name",
      'phoneNumber': "Please enter a phone number so we can reach you by phone if necessary",
      'companyName': "Please enter a company name so we can review your account ahead of time",
      'email': "Please enter an email so we can reach back out with a solution",
      'message': "Please enter a message so we can better assist you"
    };

    for(let requiredField of requiredFields) {
      if(!fields[requiredField]) {
        alert(requiredMessage[requiredField]);
        return;
      }
    }

    const msg = {
      toEmails: ['support@thsuite.com'],
      subject: 'Support Service Request from THSuite.com',
      message: `
        <div>
          <b>First Name: </b>${fields.firstName}<br>
          <b>Last Name: </b>${fields.lastName}<br>
          <b>Email: </b>${fields.email}<br>
          <b>Phone Number: </b>${fields.phoneNumber}<br>
          <b>Company Name: </b>${fields.companyName}<br>
          <b>Message: </b>${fields.message}<br>
        </div>
      `
    };

    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_LAMBDA_BASE_URL}/send`,
      data: msg
    }).then(() => setIsSuccess(true));
  };
  if (isSuccess) {
    return (
      <React.Fragment>
        <Header isShadowed />
        <section className="spaced">
          <SuccessSent />
        </section>
        <Footer />
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Header isShadowed />
      <div className="customer-service">
        <CustomerImages />
        <div className="content-wrap">
          <div className="section-heading center left black separated">
            <div className="text trimmed-width">
              <h1>Customer Service</h1>
              <img
                src="/images/backgrounds/support/section-image.png"
                srcset="/images/backgrounds/support/section-image@2x.png 2x,
             /images/backgrounds/support/section-image@3x.png 3x"
                class="contact-page-03-01"
                alt="support"
              />
              <p>
                Shoot us an email or click to chat. From setup to know-how and everything
                in between, we’ve got your back:
              </p>
              <div className="contact-info">
                <a href="mailto:support@thsuite.com">Email: support@thsuite.com</a>
              </div>
            </div>
          </div>
          <div className="form">
            <div className="input-row">
              <Input
                label="First Name"
                placeholder="Enter First Name"
                onChange={value => setFields({ firstName: value })}
              />
              <Input
                label="Last Name"
                placeholder="Enter Last Name"
                onChange={value => setFields({ lastName: value })}
              />
            </div>
            <div className="input-row">
              <Input
                label="Email Address"
                type="email"
                placeholder="Enter Email Address"
                onChange={value => setFields({ email: value })}
              />
              <Input
                label="Phone Number"
                placeholder="Enter Phone Number"
                type="tel"
                onChange={value => setFields({ phoneNumber: value })}
              />
            </div>
            <div className="input-row">
              <Input
                label="Company Name"
                placeholder="Enter Company Name"
                onChange={value => setFields({ companyName: value })}
              />
            </div>
            <div className="input-row">
              <Input
                label="Message"
                placeholder="Enter Message"
                rows={6}
                onChange={value => setFields({ message: value })}
              />
            </div>
            <div className="button">
              <button className="btn primary" onClick={handleSubmit}>
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default CustomerService;
