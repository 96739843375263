import { useEffect } from 'react';

export default function useClickOutside(ref, outsideClickCallback) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      outsideClickCallback();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}
