import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function Tos() {
  return (
    <React.Fragment>
      <Header isShadowed />
      <div className="content-wrap">
        <div className="section-heading centered left black separated">
          <div className="text">
            <h3>January 24, 2020</h3>
            <br />
            <p>THSuite LLC, a leading provider of software to the cannabis industry, is providing this notice to describe a recent data security incident and the steps we are taking to address it. </p>
            <br />
            <p>We recently discovered that certain customer records were potentially accessible on one of our servers for a short period. Specifically, an organization tasked with locating possible vulnerabilities located the data at a non-published network address, which has now been secured. We have internally found no evidence that the data was viewed by anyone other than the security analysts who notified our company that the data was potentially accessible.  The security analysts have also indicated that they did not store or otherwise expose the data, so we remain confident that no customer or patient information has been accessed or used maliciously.</p>
            <br />
            <p>In addition to our ongoing internal investigation, we will be engaging external security experts to assist with this incident, to audit our systems, and help us to improve our safeguards to better detect and prevent incidents such as this. We are continuing to review the data at issue and will notify relevant parties in the event we learn any additional facts that would trigger additional concerns under applicable law.    </p>
            <br />
            <p>THSuite takes customer and patient security seriously. We regret the concern this incident may have caused, but assure the public and our THSuite client base that we have addressed the temporary vulnerability. We further apologize to the broader cannabis industry for the unfortunate nature of this news – as an industry we work tirelessly to raise awareness for all of the good that cannabis represents to patients and customers alike, and regret any negative impact this may have on our collective work.</p>
            <br />
            <p>- The THSuite Team</p>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Tos;
