import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import GetStartedModal from './GetStartedModal';

export const Context = React.createContext(undefined);

function ModalComponent(props) {
  let [isVisible, setIsVisible] = useState(false);

  return (
    <Context.Provider value={{ isVisible, setIsVisible }}>
      {props.children}
      <GetStartedModal />
    </Context.Provider>
  );
}

export default withRouter(ModalComponent);
