import React from 'react';
import Header from './Header';
import GetStartedForm from './GetStartedForm';
import Footer from './Footer';

function GetStartedPage() {
  return (
    <React.Fragment>
      <Header isShadowed />
      <div className="content-wrap">
        <section>
          <GetStartedForm />
        </section>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default GetStartedPage;
