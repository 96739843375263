export const SOLUTIONS_INDEX_CARDS = [
  {
    title: 'Administration',
    imageUrl: '/images/solutions-index/1.png',
    srcSet: '/images/solutions-index/1@2x.png 2x, /images/solutions-index/1@3x.png 3x',
    description:
      'Operations are crucial to your dispensary, and you want them to run smoothly. THSuite ensures that everyone who helps you do business has the appropriate level of...',
    linkUrl: '/solutions/administration'
  },
  {
    title: 'Accounting',
    imageUrl: '/images/solutions-index/2.png',
    srcSet: '/images/solutions-index/2@2x.png 2x, /images/solutions-index/2@3x.png 3x',
    description:
      'Whether you’re the do-it-yourself type or have an accountant on hand, THSuite takes care of all of your accounting needs. Get day-to-day reporting on the value of your inventory...',
    linkUrl: '/solutions/accounting'
  },
  {
    title: 'Compliance Integration',
    imageUrl: '/images/solutions-index/3.png',
    srcSet: '/images/solutions-index/3@2x.png 2x, /images/solutions-index/3@3x.png 3x',
    description:
      'Stay in line with your state’s regulations, without lifting a finger. You won’t even have to click ‘send.’ Be in compliance 100% of the time, even when your internet goes down....',
    linkUrl: '/solutions/compliance-integration'
  },
  {
    title: 'Customized Reporting',
    imageUrl: '/images/solutions-index/4.png',
    srcSet: '/images/solutions-index/4@2x.png 2x, /images/solutions-index/4@3x.png 3x',
    description:
      'Reporting is the heart and soul of THSuite, offering reporting integration with your state’s API, strategic business reports and offline reporting in the event of internet failure...',
    linkUrl: '/solutions/customized-reporting'
  },
  {
    title: 'Inventory Management',
    imageUrl: '/images/solutions-index/5.png',
    srcSet: '/images/solutions-index/5@2x.png 2x, /images/solutions-index/5@3x.png 3x',
    description:
      'When it comes to managing your inventory, it’s crucial to know every step of the lifecycle. THSuite not only tracks incoming products and what’s on your shelves, it helps you make key business...',
    linkUrl: '/solutions/inventory-management'
  },
  {
    title: 'Point-Of-Sale System',
    imageUrl: '/images/solutions-index/6.png',
    srcSet: '/images/solutions-index/6@2x.png 2x, /images/solutions-index/6@3x.png 3x',
    description:
      'When you switch to THSuite, you can say goodbye to clunky interfaces, old-school data entry and being in the dark on the health of your business. Our customized-just-for-you system features...',
    linkUrl: '/solutions/pos-system'
  },
  {
    title: 'Logistics',
    imageUrl: '/images/solutions-index/7.png',
    srcSet: '/images/solutions-index/7@2x.png 2x, /images/solutions-index/7@3x.png 3x',
    description:
      'Switching to a new system can be intimidating. Will it do what you need? Will you spend hours learning how to use it only to discover a major flaw? THSuite takes the pain out of...',
    linkUrl: '/solutions/logistics'
  },
  {
    title: 'Customer Support',
    imageUrl: '/images/solutions-index/8.png',
    srcSet: '/images/solutions-index/8@2x.png 2x, /images/solutions-index/8@3x.png 3x',
    description:
      'Call us, shoot over an email or click to chat. From setup to know-how and everything in between, we’ve got your back...',
    linkUrl: '/solutions/customer-support'
  }
];

export const ADMINISTRATION_FEATURES = [
  {
    title: 'Simple Setup',
    imageUrl: '/images/solutions-pages/administration/1.png',
    srcSet:
      '/images/solutions-pages/administration/1@2x.png 2x, /images/solutions-pages/administration/1@3x.png 3x',
    description:
      'From day one, you’ll see how THSuite is different. Don’t waste hours of your time implementing the program, by simply connecting with your state’s API, all of your past data will automatically be uploaded to the system. Getting started couldn’t be easier.    '
  },
  {
    title: 'Permission-Based Functionality    ',
    imageUrl: '/images/solutions-pages/administration/2.png',
    srcSet:
      '/images/solutions-pages/administration/2@2x.png 2x, /images/solutions-pages/administration/2@3x.png 3x',
    description:
      'Set precise permissions for each employee with access customization. Ensure each budtender or administrator can access any and all actions you want them to have and block anyone at any time from accessing information they shouldn’t. As employees grow with your company, easily grant access to features they need.'
  },
  {
    title: 'Employee Management',
    imageUrl: '/images/solutions-pages/administration/3.png',
    srcSet:
      '/images/solutions-pages/administration/3@2x.png 2x, /images/solutions-pages/administration/3@3x.png 3x',
    description:
      'Forget needing another system to manage your employees. Keep track of each and every hour worked with punch-in/punch-out functionality, maintain an employee schedule and submit precise reports to payroll. '
  },
  {
    title: 'SMS/Email Campaigns',
    imageUrl: '/images/solutions-pages/administration/7.png',
    srcSet: '',
    description:
      'Launch your custom Email or SMS Campaigns across your customers for a better reach.'
  },
  {
    title: 'Discounting',
    imageUrl: '/images/solutions-pages/administration/5.png',
    srcSet:
      '/images/solutions-pages/administration/5@2x.png 2x, /images/solutions-pages/administration/5@3x.png 3x',
    description:
      'Whether you want to move product faster or create a mixed bag for easy sales, discount codes are easy to set and forget. Create a code once and make it available whenever you’d like, including automatic recurring entries. Enjoy endless discount options by specifying products, product types and packages, as well as specific days of the week or times of the day.'
  },
  {
    title: 'Loyalty Program',
    imageUrl: '/images/solutions-pages/administration/6.png',
    srcSet:
      '/images/solutions-pages/administration/6@2x.png 2x, /images/solutions-pages/administration/6@3x.png 3x',
    description:
      'Reward your most frequent customers and entice new customers to return by allowing them to accrue and redeem points for discounts on future purchases.'
  },
  {
    title: 'Point-of-Sale',
    imageUrl: '/images/solutions-pages/administration/4.png',
    srcSet:
      '/images/solutions-pages/administration/4@2x.png 2x, /images/solutions-pages/administration/4@3x.png 3x',
    description:
      'Check out has never looked better. This gorgeous, intuitive register makes ringing up merchandise painless and fun. Scan or search your way to a sale – and don’t forget the discount codes!    '
  }
];

export const ACCOUNTING_FEATURES = [
  {
    title: 'QuickBooks Integration',
    imageUrl: '/images/solutions-pages/accounting/1.png',
    srcSet:
      '/images/solutions-pages/accounting/1@2x.png 2x, /images/solutions-pages/accounting/1@3x.png 3x',
    description:
      'Happen to use QuickBooks to keep track of your company’s bottom line? We’ll connect you right to QuickBooks, allowing THSuite to continuously push your key fitgures to your accounting software while you’re busy running your business. When you need to see your numbers, they’re all right where you want them.'
  },
  {
    title: 'Custom Reports',
    imageUrl: '/images/solutions-pages/accounting/2.png',
    srcSet:
      '/images/solutions-pages/accounting/2@2x.png 2x, /images/solutions-pages/accounting/2@3x.png 3x',
    description:
      'Better understand your ordering cycle, send reports directly to your accountant and know your cost of goods sold with our custom reports. Get down to the details with granular tracking in order to see the precise value of your inventory on hand and potential profit all the way down to the variation level. Need more? We’ll gladly sculpt the reports to fit your needs.    '
  },
  {
    title: 'Tax Time',
    imageUrl: '/images/solutions-pages/accounting/3.png',
    srcSet:
      '/images/solutions-pages/accounting/3@2x.png 2x, /images/solutions-pages/accounting/3@3x.png 3x',
    description:
      'Get an accurate view of your business value and take advantage of your state’s tax deductions with our inventory reporting. Filing your taxes might actually be enjoyable for a change.    '
  }
];

export const COMPLIANCE_FEATURES = [
  {
    title: 'State API Integration',
    imageUrl: '/images/solutions-pages/compliance-integration/1.png',
    srcSet:
      '/images/solutions-pages/compliance-integration/1@2x.png 2x, /images/solutions-pages/compliance-integration/1@3x.png 3x',
    description:
      'The first thing THSuite does when onboarding is integrate with your state’s traceability system. We’ll automatically pull information from an incoming order and send it straight to the state. Every single thing is reported, so you can sit back and know it’s all being taken care of. You won’t even have to submit reports at the end of the day. Done and done.    '
  },
  {
    title: 'No More Duplicate Work',
    imageUrl: '/images/solutions-pages/compliance-integration/2.png',
    srcSet:
      '/images/solutions-pages/compliance-integration/2@2x.png 2x, /images/solutions-pages/compliance-integration/2@3x.png 3x',
    description:
      'THSuite constantly sends information to your state, creating the least amount of duplicate work on your part. Inventory reports will be sent daily and sales are transmitted in real time. Your state will know it all, just like you.'
  },
  {
    title: 'Offline Reporting',
    imageUrl: '/images/solutions-pages/compliance-integration/3.png',
    srcSet:
      '/images/solutions-pages/compliance-integration/3@2x.png 2x, /images/solutions-pages/compliance-integration/3@3x.png 3x',
    description:
      'Finally end manual reporting when your system goes down! THSuite runs on your local network and uses the system’s hardware to store information, allowing you to continue operations in the face of a system outage. When your connection returns, so does reporting, syncing up with your state to send anything that got left behind since the last transmission.'
  }
];

export const CUSTOMIZED_REPORTING_FEATURES = [
  {
    title: 'QuickBooks Integration',
    imageUrl: '/images/solutions-pages/customized-reporting/1.png',
    srcSet:
      '/images/solutions-pages/customized-reporting/1@2x.png 2x, /images/solutions-pages/customized-reporting/1@3x.png 3x',
    description:
      'THSuite connects directly to the QuickBooks API, keeping your key figures all in one place. Stay busy running your business, not your numbers.'
  },
  {
    title: 'Compliance Reporting',
    imageUrl: '/images/solutions-pages/customized-reporting/2.png',
    srcSet:
      '/images/solutions-pages/customized-reporting/2@2x.png 2x, /images/solutions-pages/customized-reporting/2@3x.png 3x',
    description:
      'THSuite automatically syncs with your state’s API, ensuring your dispensary is fully compliant with your state’s regulation. Pull the information THSuite has been collecting all day into one report for accounting and tax purposes – no data entry required.    '
  },
  {
    title: 'Sales Reporting',
    imageUrl: '/images/solutions-pages/customized-reporting/3.png',
    srcSet:
      '/images/solutions-pages/customized-reporting/3@2x.png 2x, /images/solutions-pages/customized-reporting/3@3x.png 3x',
    description:
      'See every important detail of what’s flying off your shelves so you know what will keep your customers coming back. View peak sales by day, hour and product, chart your sales over time, and know precisely when to fully staff and re-stock your dispensary to optimize your bottom line.'
  },
  {
    title: 'Inventory Reporting',
    imageUrl: '/images/solutions-pages/customized-reporting/4.png',
    srcSet:
      '/images/solutions-pages/customized-reporting/4@2x.png 2x, /images/solutions-pages/customized-reporting/4@3x.png 3x',
    description:
      'View your day-by-day inventory quantities at a glance. Then break it down by the hour to see the products, weight, type and quantity. Get insights into your inventory that help you move product more quickly via discounts or order more before running out. Find all the metrics you need to accurately report numbers for accounting and tax purposes – including valuable deductions – right in one place.    '
  },
  {
    title: 'Offline Reporting',
    imageUrl: '/images/solutions-pages/customized-reporting/5.png',
    srcSet:
      '/images/solutions-pages/customized-reporting/5@2x.png 2x, /images/solutions-pages/customized-reporting/5@3x.png 3x',
    description:
      'Don’t get caught off guard in the event of an internet outage. Your dispensary will function as normal, with no manual data entry when your service returns. THSuite continues to allow sales, produce reports and keep you in compliance, ensuring 100% uptime, all the time.    '
  }
];

export const INVENTORY_MANAGEMENT_FEATURES = [
  {
    title: 'Manage Product',
    imageUrl: '/images/solutions-pages/inventory-management/1.png',
    srcSet:
      '/images/solutions-pages/inventory-management/1@2x.png 2x, /images/solutions-pages/inventory-management/1@3x.png 3x',
    description:
      'Easily create different variations of products from a single item, including seamlessly converting your left over flower from one package into a new package for shake/trim. See every product at your disposal, in every form and at every price point. In addition to easy barcode scanning, our intuitive search bar makes finding products in your store a snap.    '
  },
  {
    title: 'Adjust Inventory',
    imageUrl: '/images/solutions-pages/inventory-management/2.png',
    srcSet:
      '/images/solutions-pages/inventory-management/2@2x.png 2x, /images/solutions-pages/inventory-management/2@3x.png 3x',
    description:
      'As products availability shifts to account for loss, you’ll be able to easily and quickly adjust the inventory in THSuite. Account for each and every item that is dried up, accidentally opened, lost, stolen or otherwise unusable and report it back to the state automatically.    '
  },
  {
    title: 'Filter Your View',
    imageUrl: '/images/solutions-pages/inventory-management/3.png',
    srcSet:
      '/images/solutions-pages/inventory-management/3@2x.png 2x, /images/solutions-pages/inventory-management/3@3x.png 3x',
    description:
      'Understand the complete picture of your inventory and filter your view by product, supplier or quantity to quickly know what you have available, and what demands attention. Re-order before you run out, and make swift decisions based on what you see at-a-glance.'
  },
  {
    title: 'Package Audit',
    imageUrl: '/images/solutions-pages/inventory-management/4.png',
    srcSet:
      '/images/solutions-pages/inventory-management/4@2x.png 2x, /images/solutions-pages/inventory-management/4@3x.png 3x',
    description:
      'Quickly reconcile why your physical inventory doesn’t match your virtual inventory by viewing every event that affects your packages. See what actions affected your original quantity, and who took each action. Gain valuable insights into your package inventory from start to finish.    '
  },
  {
    title: 'System Alerts',
    imageUrl: '/images/solutions-pages/inventory-management/5.png',
    srcSet:
      '/images/solutions-pages/inventory-management/5@2x.png 2x, /images/solutions-pages/inventory-management/5@3x.png 3x',
    description:
      'Stay on top of your inventory with automated alerts that flag issues such as missing information, low inventory and more. Send an email to your contact of choice when an alert is tripped, then fix the problem with one simple click.    '
  }
];

export const POS_SYSTEM_FEATURES = [
  {
    title: 'Inventory Management',
    imageUrl: '/images/solutions-pages/pos-system/1.png',
    srcSet:
      '/images/solutions-pages/pos-system/1@2x.png 2x, /images/solutions-pages/pos-system/1@3x.png 3x',
    description:
      'When it comes to managing your inventory, it’s crucial to know every step of the lifecycle. THSuite not only tracks incoming products and what’s on your shelves, it helps you make key business...'
  },
  {
    title: 'Reporting',
    imageUrl: '/images/solutions-pages/pos-system/2.png',
    srcSet:
      '/images/solutions-pages/pos-system/2@2x.png 2x, /images/solutions-pages/pos-system/2@3x.png 3x',
    description:
      'Reporting is the heart and soul of THSuite, offering reporting integration with your state’s API, strategic business reports and offline reporting in the event of internet failure...'
  },
  // {
  //   title: 'Patient Management',
  //   imageUrl: '/images/solutions-pages/pos-system/3.png',
  //   srcSet:
  //     '/images/solutions-pages/pos-system/3@2x.png 2x, /images/solutions-pages/pos-system/3@3x.png 3x',
  //   description: ''
  // },
  {
    title: 'Point of Sale',
    imageUrl: '/images/solutions-pages/pos-system/4.png',
    srcSet:
      '/images/solutions-pages/pos-system/4@2x.png 2x, /images/solutions-pages/pos-system/4@3x.png 3x',
    description:
      'When you switch to THSuite, you can say goodbye to clunky interfaces, old-school data entry and being in the dark on the health of your business. Our customized-just-for-you system features...'
  },
  {
    title: 'Customer Support',
    imageUrl: '/images/solutions-pages/pos-system/5.png',
    srcSet:
      '/images/solutions-pages/pos-system/5@2x.png 2x, /images/solutions-pages/pos-system/5@3x.png 3x',
    description:
      'Call us, shoot over an email or click to chat. From setup to know-how and everything in between, we’ve got your back...'
  },
  {
    title: 'Employee Management With Tiered Permissions',
    imageUrl: '/images/solutions-pages/pos-system/6.png',
    srcSet:
      '/images/solutions-pages/pos-system/6@2x.png 2x, /images/solutions-pages/pos-system/6@3x.png 3x',
    description:
      'Forget needing another system to manage your employees. Keep track of each and every hour worked with punch-in/punch-out functionality, maintain an employee schedule and submit precise reports to payroll.'
  },
  {
    title: 'Custom Discount Creation',
    imageUrl: '/images/solutions-pages/pos-system/7.png',
    srcSet:
      '/images/solutions-pages/pos-system/7@2x.png 2x, /images/solutions-pages/pos-system/7@3x.png 3x',
    description:
      'Whether you want to move product faster or create a mixed bag for easy sales, discount codes are easy to set and forget. Create a code once and make it available whenever you’d like, including automatic recurring entries. Enjoy endless discount options by specifying products, product types and packages, as well as specific days of the week or times of the day.'
  },
  {
    title:
      'Integration With Popular Aggregators, Including Leafly and Weedmaps, For Easy Menu Updating',
    imageUrl: '/images/solutions-pages/pos-system/8.png',
    srcSet:
      '/images/solutions-pages/pos-system/8@2x.png 2x, /images/solutions-pages/pos-system/8@3x.png 3x',
    description:
      'Keep up with any third-party service all in one place. From Quickbooks to Baker and other marketing services, THSuite’s custom API can push and pull data from any third-party service you’re already using. Forget about the headaches of going back and forth between systems and apps – we’ve got it handled.'
  },
  {
    title: 'SMS/Email Campaigns',
    imageUrl: '/images/solutions-pages/pos-system/9.png',
    srcSet:
      '/images/solutions-pages/pos-system/9@2x.png 2x, /images/solutions-pages/pos-system/9@3x.png 3x',
    description:
      'Launch your custom Email or SMS Campaigns across your customers for a better reach.'
  }
];

export const LOGISTICS_FEATURES = [
  {
    title: 'Across-the-Board Customization',
    imageUrl: '/images/solutions-pages/logistics/1.png',
    srcSet:
      '/images/solutions-pages/logistics/1@2x.png 2x, /images/solutions-pages/logistics/1@3x.png 3x',
    description:
      'Unlike most POS systems, THSuite is not a rigid, ‘one-size-fits-all’ solution. We know that your needs are different, and we will work alongside you to customize the product to your dispensary. Want a new report? It’s all yours. New functionality to reconcile inventory? Done! More alerts? Coming right up! THSuite will happily customize any function, because your technology should work for you, not the other way around.    '
  },
  {
    title: 'Third-Party Integrations',
    imageUrl: '/images/solutions-pages/logistics/2.png',
    srcSet:
      '/images/solutions-pages/logistics/2@2x.png 2x, /images/solutions-pages/logistics/2@3x.png 3x',
    description:
      'Keep up with any third-party service all in one place. From Quickbooks to Baker and other marketing services, THSuite’s custom API can push and pull data from any third-party service you’re already using. Forget about the headaches of going back and forth between systems and apps – we’ve got it handled.'
  }
];

export const CUSTOMER_SUPPORT_FEATURES = [
  // {
  //   title: '24/7 Access',
  //   imageUrl: '/images/solutions-pages/customer-support/1.png',
  //   srcSet:
  //     '/images/solutions-pages/customer-support/1@2x.png 2x, /images/solutions-pages/customer-support/1@3x.png 3x',
  //   description:
  //     'We know that the unexpected doesn’t happen on a schedule. So we’re around, whether it’s two in the morning or two in the afternoon. We’ll get your questions answered, solve your problems and keep your system running smoothly so you can get back to business.    '
  // },
  {
    title: 'Migration',
    imageUrl: '/images/solutions-pages/customer-support/2.png',
    srcSet:
      '/images/solutions-pages/customer-support/2@2x.png 2x, /images/solutions-pages/customer-support/2@3x.png 3x',
    description:
      'THSuite takes the hassle out of migrating data from your old system. By importing your data from your state’s API, we’re able to get you up and running with no effort on your part. It’ll be like you’ve been using THSuite this whole time.    '
  },
  {
    title: 'Knowledge Portal',
    imageUrl: '/images/solutions-pages/customer-support/4.png',
    srcSet:
      '/images/solutions-pages/customer-support/4@2x.png 2x, /images/solutions-pages/customer-support/4@3x.png 3x',
    description:
      'Want to try and solve the issue on your own? Our vast knowledge base is up for grabs. Here you can learn more about THSuite’s capabilities, ensuring you get the most out of the system each and every day.    '
  }
];
