import React, { Fragment } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { INVENTORY_MANAGEMENT_FEATURES } from '../../helpers/solutions-mock';
import FeatureCell from '../../components/FeatureCell';
import TakeFirstStep from '../../components/TakeFirstStep';
import PartnerLogos from '../../components/PartnerLogos';
import SolutionsSubnav from '../../components/SolutionsSubnav';

function InventoryManagement() {
  return (
    <Fragment>
      <Header isShadowed activeLink="solutions" />
      <div className="content-wrap">
        <SolutionsSubnav />
        <section>
          <div className="section-heading solutions-section black">
            <div className="text">
              <h3>
                <img
                  src="/images/solutions-index/5.png"
                  srcSet="/images/solutions-index/5@2x.png 2x,
             /images/solutions-index/5@3x.png 3x"
                  alt="icon"
                />
                Inventory Management
              </h3>
              <h1>
                When it comes to managing your inventory, it’s crucial to know every step of the
                lifecycle.
              </h1>
              <p>
                THSuite not only tracks incoming products and what’s on your shelves, it helps you
                make key business decisions on sales offers and loyalty discounts that will keep
                your customers coming back again and again. Find your most popular products in a
                click, stay on top of your purchasing needs and handle returned inventory in one
                easy to manage portal.
                <br />
                <br />
                In addition, our built-in inventory reporting provides all the metrics you need to
                accurately report all of your financials for accounting and tax purposes. Should you
                need more, we can do that, too. All of your numbers, at your fingertips, when and
                how you need to see them.
              </p>
              {/* <button className="btn green">View Features</button> */}
            </div>
            <div className="image">
              <img
                src="/images/solutions-pages/inventory-management/section-image.png"
                srcSet="/images/solutions-pages/inventory-management/section-image@2x.png 2x,
             /images/solutions-pages/inventory-management/section-image@3x.png 3x"
                alt="icon"
              />
            </div>
          </div>
        </section>
        <section>
          <div className="section-heading center black">
            <h1>Our Inventory Management Features:</h1>
          </div>
        </section>
        <div className="features-grid">
          {INVENTORY_MANAGEMENT_FEATURES.map((feature, featureIndex) => (
            <FeatureCell key={`feature-${featureIndex}`} {...feature} />
          ))}
        </div>
      </div>
      <TakeFirstStep />
      <PartnerLogos />
      <Footer />
    </Fragment>
  );
}

export default InventoryManagement;
