import React, { useReducer, useState } from 'react';
import Input from './Input';
import Select from './Select';
import { US_STATES } from './mock';
import SuccessSent from './SuccessSent';
import axios from 'axios';

function GetStartedForm() {
  const [fields, setFields] = useReducer((o, n) => ({ ...o, ...n }), {
    isOperating: false,
    state: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    company: '',
    typeOfBusiness: ''
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [isOperating, setIsOperating] = useState(true);
  const handleSubmit = () => {
    const msg = {
      toEmails: ['demo@thsuite.com'],
      subject: 'Getting Started Request from THSuite.com',
      message: `
        <div>
          You have a new lead <br>
          =======================================<br>
          <b>Currently Operating: </b>${isOperating ? 'Yes' : 'No'}<br>
          <b>Operating In: </b>${fields.state}<br>
          <b>First Name: </b>${fields.firstName}<br>
          <b>Last Name: </b>${fields.lastName}<br>
          <b>Email: </b>${fields.email}<br>
          <b>Phone Number: </b>${fields.phoneNumber}<br>
          <b>Company: </b>${fields.company}<br>
          <b>Type of Business: </b>${fields.typeOfBusiness}<br>
        </div>
      `
    };

    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_LAMBDA_BASE_URL}/send`,
      data: msg
    }).then(() => setIsSuccess(true));

  };
  if (isSuccess) {
    return <SuccessSent />;
  }
  return (
    <React.Fragment>
      <div className="section-heading center black">
        <div className="text">
          <img
            src="/images/take-first-step/leaf-icon.png"
            srcSet="/images/take-first-step/leaf-icon@2x.png 2x,
             /images/take-first-step/leaf-icon@3x.png 3x"
            alt="leaf"
          />
          <h1>Getting Started</h1>
          <p>
            THSuite is a unique and powerful seed to the sale ERP solution. <br />
            To see how THSuite can help you with your business submit the short form below:
          </p>
        </div>
      </div>
      <div className="get-started-form">
        <div className="input-row">
          <Select
            label="Are you currently operating?"
            placeholder="Select Yes or No"
            options={[
              { value: 'no', label: 'No' },
              { value: 'yes', label: 'Yes' }
            ]}
            onChange={option => setIsOperating(option.value === 'yes')}
          />
          <Select
            label={
              isOperating ? `Where are you currently operating?` : 'Where is your license held?'
            }
            placeholder="Select a State"
            options={US_STATES}
            onChange={option => setFields({ state: option.value })}
          />
        </div>
        <div className="provide-contact">
          Please provide your contact information so we can get in touch with you:
        </div>
        <div className="input-row">
          <Input
            label="First Name"
            placeholder="Enter First Name"
            onChange={value => setFields({ firstName: value })}
          />
          <Input
            label="Last Name"
            placeholder="Enter Last Name"
            onChange={value => setFields({ lastName: value })}
          />
        </div>
        <div className="input-row">
          <Input
            label="Email Address"
            placeholder="Enter Email Address"
            type="email"
            onChange={value => setFields({ email: value })}
          />
          <Input
            label="Phone Number"
            placeholder="Enter Phone Number"
            type="tel"
            onChange={value => setFields({ phoneNumber: value })}
          />
        </div>
        <div className="input-row">
          <Input
            label="Company"
            placeholder="Enter Company Name"
            onChange={value => setFields({ company: value })}
          />
          <Input
            label="Type of Business"
            placeholder="Select Type of Business"
            onChange={value => setFields({ typeOfBusiness: value })}
          />
        </div>
        <div className="button">
          <button className="btn primary" onClick={handleSubmit}>
            Submit Request
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GetStartedForm;
