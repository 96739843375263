import React, { Fragment } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { POS_SYSTEM_FEATURES } from '../../helpers/solutions-mock';
import FeatureCell from '../../components/FeatureCell';
import TakeFirstStep from '../../components/TakeFirstStep';
import PartnerLogos from '../../components/PartnerLogos';
import SolutionsSubnav from '../../components/SolutionsSubnav';

function POSSystem() {
  return (
    <Fragment>
      <Header isShadowed activeLink="solutions" />
      <div className="content-wrap">
        <SolutionsSubnav />
        <section>
          <div className="section-heading solutions-section black">
            <div className="text">
              <h3>
                <img
                  src="/images/solutions-index/6.png"
                  srcSet="/images/solutions-index/6@2x.png 2x,
             /images/solutions-index/6@3x.png 3x"
                  alt="icon"
                />
                Point-Of-Sale System
              </h3>
              <h1>When you switch to THSuite, you can say goodbye to clunky interfaces.</h1>
              <p>
                Old-school data entry and being in the dark on the health of your business. Our
                customized-just-for-you system features robust reporting, automatic compliance
                integration and seamless inventory management. Offline reporting means no more
                anxiety about staying compliant in the event of a system outage, and 24/7 customer
                support means you don’t have to go it alone.
              </p>
              {/* <button className="btn green">View Features</button> */}
            </div>
            <div className="image">
              <img
                src="/images/solutions-pages/pos-system/section-image.png"
                srcSet="/images/solutions-pages/pos-system/section-image@2x.png 2x,
             /images/solutions-pages/pos-system/section-image@3x.png 3x"
                alt="icon"
              />
            </div>
          </div>
        </section>
        <section>
          <div className="section-heading center black">
            <h1>Our Point-Of-Sale System Features:</h1>
          </div>
        </section>
        <div className="features-grid">
          {POS_SYSTEM_FEATURES.map((feature, featureIndex) => (
            <FeatureCell key={`feature-${featureIndex}`} {...feature} />
          ))}
        </div>
      </div>
      <TakeFirstStep />
      <PartnerLogos />
      <Footer />
    </Fragment>
  );
}

export default POSSystem;
