import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useState } from 'react';

function Header({ isShadowed = false, activeLink }) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  return (
    <header className={classNames('header', { isShadowed, open: isMenuVisible })}>
      <div className="inner">
        <Link to="/" className="logo">
          <img src="/images/logo.png" alt="" />
        </Link>
        <ul className={classNames('links', { visible: isMenuVisible })}>
          <li>
            <Link to="/" className={classNames('link', { active: activeLink === 'home' })}>
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/solutions"
              className={classNames('link', {
                active: activeLink === 'solutions'
              })}
            >
              Solutions
            </Link>
          </li>
          {/* <li>
            <Link
              to="/customized-cultivation"
              className={classNames('link', {
                active: activeLink === 'customized-cultivation'
              })}
            >
              Customized Cultivation
            </Link>
          </li> */}
          <li>
            <Link
              to="/partners"
              className={classNames('link', {
                active: activeLink === 'partners'
              })}
            >
              Partners
            </Link>
          </li>
          <li>
            <Link className="btn primary" to="/get-started">
              Book a Demo
            </Link>
          </li>
        </ul>
        <div className="toggle" onClick={() => setIsMenuVisible(!isMenuVisible)}>
          {isMenuVisible ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
        </div>
      </div>
    </header>
  );
}

export default Header;
