export const PARTNERS_CARDS = [
  {
    imageUrl: '/images/partners/canndelta.png',
    srcSet: undefined,
    description: '',
    externalUrl: 'https://canndelta.com'
  },
  {
    imageUrl: '/images/partners/1.png',
    srcSet: '/images/partners/1@2x.png 2x, /images/partners/1@3x.png 3x',
    description: '',
    linkUrl: '/partners/metrc'
  },
  {
    imageUrl: '/images/partners/7.png',
    srcSet: '',
    description: '',
    linkUrl: '/partners/metrc'
  },
  {
    imageUrl: '/images/partners/2.png',
    srcSet: '/images/partners/2@2x.png 2x, /images/partners/2@3x.png 3x',
    description: '',
    linkUrl: '/partners/metrc'
  },
  {
    imageUrl: '/images/partners/3.png',
    srcSet: '/images/partners/3@2x.png 2x, /images/partners/3@3x.png 3x',
    description: '',
    linkUrl: '/partners/metrc'
  },
  {
    imageUrl: '/images/partners/4.png',
    srcSet: '/images/partners/4@2x.png 2x, /images/partners/4@3x.png 3x',
    description: '',
    linkUrl: '/partners/metrc'
  },
  {
    imageUrl: '/images/partners/5.png',
    srcSet: '/images/partners/5@2x.png 2x, /images/partners/5@3x.png 3x',
    description: '',
    linkUrl: '/partners/metrc'
  },
  {
    imageUrl: '/images/partners/6.png',
    srcSet: '/images/partners/6@2x.png 2x, /images/partners/6@3x.png 3x',
    description: '',
    linkUrl: '/partners/metrc'
  },
  {
    imageUrl: '/images/partners/weedmaps.svg',
    srcSet: undefined,
    description: '',
    linkUrl: '/partners/weedmaps'
  },
  {
    imageUrl: '/images/partners/alpineiq.webp',
    srcSet: undefined,
    description: '',
    linkUrl: '/partners/alpine'
  },
  {
    imageUrl: '/images/partners/releaf.png',
    srcSet: undefined,
    description: '',
    linkUrl: '/partners/releaf'
  },
  {
    imageUrl: '/images/partners/greenscreens.png',
    srcSet: undefined,
    description: '',
    linkUrl: '/partners/greenscreens'
  },
  {
    imageUrl: '/images/partners/naturepay.png',
    srcSet: undefined,
    description: '',
    linkUrl: '/partners/naturepay'
  }
];
