import React from "react";

function FeatureCell({ title, imageUrl, description, srcSet }) {
  return (
    <div className="feature">
      <div className="icon">
        <img src={imageUrl} alt="" srcSet={srcSet} />
      </div>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
}

export default FeatureCell;
