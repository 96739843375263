import React, { Fragment } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { LOGISTICS_FEATURES } from '../../helpers/solutions-mock';
import FeatureCell from '../../components/FeatureCell';
import TakeFirstStep from '../../components/TakeFirstStep';
import PartnerLogos from '../../components/PartnerLogos';
import SolutionsSubnav from '../../components/SolutionsSubnav';

function Logistics() {
  return (
    <Fragment>
      <Header isShadowed activeLink="solutions" />
      <div className="content-wrap">
        <SolutionsSubnav />
        <section>
          <div className="section-heading solutions-section black">
            <div className="text">
              <h3>
                <img
                  src="/images/solutions-index/7.png"
                  srcSet="/images/solutions-index/7@2x.png 2x,
             /images/solutions-index/7@3x.png 3x"
                  alt="icon"
                />
                Logistics
              </h3>
              <h1>Switching to a new system can be intimidating.</h1>
              <p>
                Will it do what you need? Will you spend hours learning how to use it only to
                discover a major flaw? THSuite takes the pain out of switching by customizing the
                system to your precise needs, answering every single question, and ensuring your
                success from day one.
              </p>
              {/* <button className="btn green">View Features</button> */}
            </div>
            <div className="image">
              <img
                src="/images/solutions-pages/logistics/section-image.png"
                srcSet="/images/solutions-pages/logistics/section-image@2x.png 2x,
             /images/solutions-pages/logistics/section-image@3x.png 3x"
                alt="icon"
              />
            </div>
          </div>
        </section>
        <section>
          <div className="section-heading center black">
            <h1>Our Logistics Features:</h1>
          </div>
        </section>
        <div className="features-grid">
          {LOGISTICS_FEATURES.map((feature, featureIndex) => (
            <FeatureCell key={`feature-${featureIndex}`} {...feature} />
          ))}
        </div>
      </div>
      <TakeFirstStep />
      <PartnerLogos />
      <Footer />
    </Fragment>
  );
}

export default Logistics;
