import React, { useContext } from 'react';
import classNames from 'classnames';
import { Context as ModalContext } from './ModalContext';

function GetStartedModal() {
  const { isVisible } = useContext(ModalContext);
  return (
    <div className={classNames('modal-outer', { active: isVisible })}>
      <div className="modal-inner"></div>
    </div>
  );
}

export default GetStartedModal;
