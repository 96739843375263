import React from 'react';

function CustomerImages() {
  return (
    <React.Fragment>
      <div className="right-images">
        <img
          src="/images/backgrounds/support/right-big.png"
          srcset="/images/backgrounds/support/right-big@2x.png 2x,
             /images/backgrounds/support/right-big@3x.png 3x"
          className="right-big"
          alt="circle"
        />
        <img
          src="/images/backgrounds/support/right-small.png"
          srcset="/images/backgrounds/support/right-small@2x.png 2x,
             /images/backgrounds/support/right-small@3x.png 3x"
          className="right-small"
          alt="circle"
        />
      </div>
      <div className="left-images">
        <img
          src="/images/backgrounds/support/left-big.png"
          srcset="/images/backgrounds/support/left-big@2x.png 2x,
             /images/backgrounds/support/left-big@3x.png 3x"
          className="left-big"
          alt="circle"
        />
        <img
          src="/images/backgrounds/support/left-small.png"
          srcset="/images/backgrounds/support/left-small@2x.png 2x,
             /images/backgrounds/support/left-small@3x.png 3x"
          className="left-small"
          alt="circle"
        />
      </div>
    </React.Fragment>
  );
}

export default CustomerImages;
