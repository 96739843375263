import React from 'react';

function Features() {
  return (
    <section>
      <div className="section-heading center black">
        <div className="text">
          <h1>THSuite Features</h1>
          <p>
            We worked directly with cannabis dispensary owners and operators to find their pain{' '}
            <br /> points with other POS systems.{' '}
          </p>
        </div>
      </div>
      <div className="features">
        <div className="inner">
          <div className="feature">
            <div className="icon">
              <img
                alt="icon"
                src="/images/features/home-icons-07.png"
                srcSet="/images/features/home-icons-07@2x.png 2x,
             /images/features/home-icons-07@3x.png 3x"
                className="home-icons-07"
              />
            </div>
            <h3>Real-Time Compliance Integration</h3>
            <p>
              The THSuite platform ensures seamless and automatic integration with your state’s API
              traceability system. Never spend another minute manually entering data required by
              your state.
            </p>
          </div>
          <div className="feature">
            <div className="icon">
              <img
                alt="icon"
                src="/images/features/home-icons-04.png"
                srcSet="/images/features/home-icons-04@2x.png 2x,
             /images/features/home-icons-04@3x.png 3x"
                className="home-icons-04"
              />
            </div>
            <h3>Advanced Reporting</h3>
            <p>
              Easy to run and easy to read reports, including inventory and peak sales time
              reporting. Your most important information is at your fingertips, helping you make the
              best decisions for your business.
            </p>
          </div>
          <div className="feature">
            <div className="icon">
              <img
                alt="icon"
                src="/images/features/home-icons-08.png"
                srcSet="/images/features/home-icons-08@2x.png 2x,
             /images/features/home-icons-08@3x.png 3x"
                className="home-icons-08"
              />
            </div>
            <h3>Live Customer Support</h3>
            <p>Have a problem you just can’t solve? We’re here for you.</p>
          </div>
        </div>
        <div className="inner">
          <div className="feature">
            <div className="icon">
              <img
                alt="icon"
                src="/images/features/home-icons-01.png"
                srcSet="/images/features/home-icons-01@2x.png 2x,
             /images/features/home-icons-01@3x.png 3x"
                className="home-icons-01"
              />
            </div>
            <h3>Uniquely Customized</h3>
            <p>
              We work with your dispensary to provide a 100% customized solution for your precise
              needs. Want something unique to your business? All you have to do is ask.
            </p>
          </div>
          <div className="feature">
            <div className="icon">
              <img
                alt="icon"
                src="/images/features/home-icons-06.png"
                srcSet="/images/features/home-icons-06@2x.png 2x,
             /images/features/home-icons-06@3x.png 3x"
                className="home-icons-06"
              />
            </div>
            <h3>Permission-Based Functionality</h3>
            <p>
              From Budtender to Operations Director, ensure the right people have access to the
              right tools. Block high-level access from those who don’t need it and open it up for
              those who do.
            </p>
          </div>
          <div className="feature">
            <div className="icon">
              <img
                alt="icon"
                src="/images/features/home-icons-05.png"
                srcSet="/images/features/home-icons-05@2x.png 2x,
             /images/features/home-icons-05@3x.png 3x"
                className="home-icons-05"
              />
            </div>
            <h3>Offline Reporting</h3>
            <p>
              Even when your connection fails,THSuite goes on. Operations continue as normal if your
              internet goes out, meaning no downtime for you or your customers.
            </p>
          </div>
        </div>
        <div className="inner">
          <div className="feature">
            <div className="icon">
              <img
                alt="icon"
                src="/images/features/home-icons-02.png"
                srcSet="/images/features/home-icons-02@2x.png 2x,
             /images/features/home-icons-02@3x.png 3x"
                className="home-icons-02"
              />
            </div>
            <h3>Intuitive Design</h3>
            <p>
              A system built for dispensaries, by dispensaries. Everything you need is at your
              fingertips, with easy search functions in a simple user interface.
            </p>
          </div>
          <div className="feature">
            <div className="icon">
              <img
                alt="icon"
                src="/images/features/home-icons-09.png"
                srcSet="/images/features/home-icons-09@2x.png 2x,
             /images/features/home-icons-09@3x.png 3x"
                className="home-icons-09"
              />
            </div>
            <h3>Simple Switchover</h3>
            <p>
              Access your historical data through your state’s API and operate without interruption.
              You’re just a few clicks away from a better POS.
            </p>
          </div>
          <div className="feature">
            <div className="icon">
              <img
                alt="icon"
                src="/images/features/home-icons-03.png"
                srcSet="/images/features/home-icons-03@2x.png 2x,
             /images/features/home-icons-03@3x.png 3x"
                className="home-icons-03"
              />
            </div>
            <h3>Launch Support</h3>
            <p>
              We won’t leave you high and dry on your first day with a new system. We’ll train your
              team and stick around to ensure a seamless switch.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
