import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const Subnav = [
  { id: 'administration', label: 'Administration' },
  { id: 'accounting', label: 'Accounting' },
  { id: 'compliance-integration', label: 'Compliance Integration' },
  { id: 'customized-reporting', label: 'Customized Reporting' },
  { id: 'inventory-management', label: 'Inventory Management' },
  { id: 'pos-system', label: 'Point-of-Sale System' },
  { id: 'logistics', label: 'Logistics' },
  { id: 'customer-support', label: 'Customer Support' }
];

function SolutionsSubnav() {
  const navRef = useRef(null);
  const location = useLocation();
  useEffect(() => {
    if (
      ['pos-system', 'logistics', 'customer-support'].some(val => location.pathname.includes(val))
    ) {
      navRef.current.scrollLeft = 700;
    }
  }, [location.pathname]);
  return (
    <div className="solutions-subnav-wrap">
      <div className="solutions-subnav" ref={navRef}>
        {Subnav.map(item => (
          <Link
            key={`subnav-${item.id}`}
            to={`/solutions/${item.id}`}
            className={classNames('link', { active: location.pathname.includes(item.id) })}
          >
            {item.label}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default SolutionsSubnav;
