import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { SOLUTIONS_INDEX_CARDS } from '../../helpers/solutions-mock';
import SolutionCell from '../../components/SolutionCell';
import TakeFirstStep from '../../components/TakeFirstStep';
import PartnerLogos from '../../components/PartnerLogos';
import SolutionsSubnav from '../../components/SolutionsSubnav';

function Solutions() {
  return (
    <React.Fragment>
      <Header isShadowed activeLink="solutions" />
      <div className="content-wrap">
        <SolutionsSubnav />
        <section>
          <div className="section-heading centered left black">
            <div className="image">
              <img
                src="/images/solutions-image-pos-system.png"
                srcSet="/images/solutions-image-pos-system@2x.png 2x,
             /images/solutions-image-pos-system@3x.png 3x"
                alt="icon"
              />
            </div>
            <div className="text">
              <h3>THSuite Solutions</h3>
              <h1>It's time for a better POS.</h1>
              <p>
                Your cannabis point-of-sale system should hold nothing back. Not only should you
                expect security, compliance integration and simplicity, it should help you grow your
                business, too. We worked directly with cannabis dispensary owners and operators to
                find their pain points with other POS systems.
                <br /> <br />
                We dug into the nightmares of manual data entry and heard countless tales of
                time-consuming setup, only to discover the system wouldn’t produce reports as needed
                or make compliance any easier. Even worse, if the system went down, they were stuck
                reporting information as if they had no system at all, sometimes forcing a complete
                shutdown of the store.
              </p>
            </div>
          </div>
        </section>
        <div className="solutions-grid">
          {SOLUTIONS_INDEX_CARDS.map(card => (
            <SolutionCell {...card} key={card.linkUrl} />
          ))}
        </div>
      </div>
      <TakeFirstStep />
      <PartnerLogos />
      <Footer />
    </React.Fragment>
  );
}

export default Solutions;
