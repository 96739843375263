import React, { Fragment } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { COMPLIANCE_FEATURES } from '../../helpers/solutions-mock';
import FeatureCell from '../../components/FeatureCell';
import TakeFirstStep from '../../components/TakeFirstStep';
import PartnerLogos from '../../components/PartnerLogos';
import SolutionsSubnav from '../../components/SolutionsSubnav';

function ComplianceIntegration() {
  return (
    <Fragment>
      <Header isShadowed activeLink="solutions" />
      <div className="content-wrap">
        <SolutionsSubnav />
        <section>
          <div className="section-heading solutions-section black">
            <div className="text">
              <h3>
                <img
                  src="/images/solutions-index/3.png"
                  srcSet="/images/solutions-index/3@2x.png 2x,
             /images/solutions-index/3@3x.png 3x"
                  alt="icon"
                />
                Compliance Integration
              </h3>
              <h1>Stay in line with your state’s regulations without lifting a finger.</h1>
              <p>
                You won’t even have to click ‘send.’ Be in compliance 100% of the time, even when
                your internet goes down.
              </p>
              {/* <button className="btn green">View Features</button> */}
            </div>
            <div className="image">
              <img
                src="/images/solutions-pages/compliance-integration/section-image.png"
                srcSet="/images/solutions-pages/compliance-integration/section-image@2x.png 2x,
             /images/solutions-pages/compliance-integration/section-image@3x.png 3x"
                alt="icon"
              />
            </div>
          </div>
        </section>
        <section>
          <div className="section-heading center black">
            <h1>Our Compliance Integration Features:</h1>
          </div>
        </section>
        <div className="features-grid">
          {COMPLIANCE_FEATURES.map((feature, featureIndex) => (
            <FeatureCell key={`feature-${featureIndex}`} {...feature} />
          ))}
        </div>
      </div>
      <TakeFirstStep />
      <PartnerLogos />
      <Footer />
    </Fragment>
  );
}

export default ComplianceIntegration;
