import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="inner">
        <div className="top-row">
          <div className="col main-links">
            <div className="left">
              <Link to="/">Home</Link>
              <Link to="/customized-cultivation">Customized Cultivation</Link>
            </div>
            <div className="right">
              <Link to="/partners">Partners</Link>
              <Link to="/get-started">Get Started</Link>
            </div>
          </div>
          <div className="col">
            <div className="links">
              <div className="left">
                <Link to="/solutions">Solutions</Link>
                <Link to="/solutions/administration">Administration</Link>
                <Link to="/solutions/accounting">Accounting</Link>
                <Link to="/solutions/compliance-integration">Compliance Integration</Link>
                <Link to="/solutions/customer-support">Customer Support</Link>
              </div>
              <div className="right">
                <Link to="/solutions/customized-reporting">Customized Reporting</Link>
                <Link to="/solutions/inventory-management">Inventory Management</Link>
                <Link to="/solutions/logistics">Logistics</Link>
                <Link to="/solutions/pos-system">Point-Of-Sale System</Link>
              </div>
            </div>
          </div>
          <div className="col customer-service">
            <p>
              Need help or have questions?
              <br />
              Shoot us an email.
            </p>
            <div className="links">
              <div>
                <a href="mailto:sales@THSuite.com" target="_blank" rel="noopener noreferrer">
                  sales@THSuite.com
                </a>
              </div>
            </div>
            <Link to="/customer-service" className="btn primary">
              Customer Serivce
            </Link>
          </div>
        </div>

        <div className="bottom-row">
          <div className="logo">
            <img
              src="/images/logo-white-2.png"
              srcSet="/images/logo-white-2@2x.png 2x,
           /images/logo-white-2@3x.png 3x"
              className="logo-white-2"
              alt="icon"
            />
          </div>

          <p className="text">
            © {year} THSuite, All Rights Reserved. / <Link to="/privacy-policy">Privacy</Link> /{' '}
            <Link to="/terms-of-service">Terms of Services</Link>
          </p>

          <p className="text-mobile">
            © {year} THSuite, All Rights Reserved.
            <span>
              <Link to="/privacy-policy">Privacy</Link> /{' '}
              <Link to="/terms-of-service">Terms of Services</Link>
            </span>
          </p>

          <div className="links">
            <Link to="#">
              <i className="fab fa-facebook-f"></i>
            </Link>
            <Link to="#">
              <i className="fab fa-instagram"></i>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
