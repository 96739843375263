import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SolutionCell, { SolutionLinkCell } from '../../components/SolutionCell';
import TakeFirstStep from '../../components/TakeFirstStep';
import PartnerLogos from '../../components/PartnerLogos';
import { PARTNERS_CARDS } from './mock';

function Partners() {
  return (
    <React.Fragment>
      <Header isShadowed activeLink="partners" />
      <div className="content-wrap">
        <section>
          <div className="section-heading centered left black">
            <div className="text">
              <h3>THSuite's Partners</h3>
              <h1>For smoother integration</h1>
              <p>
                Keep up with any third-party service all in one place. From Quickbooks to Baker and
                other marketing services, THSuite’s custom API can push and pull data from any
                third-party service you’re already using. Forget about the headaches of going back
                and forth between systems and apps – we’ve got it handled.
              </p>
            </div>
          </div>
        </section>
        <div className="solutions-grid partners-grid">
          {PARTNERS_CARDS.map(card =>
            card.externalUrl ? (
              <SolutionLinkCell key={card.externalUrl} {...card} />
            ) : (
              <SolutionCell {...card} key={card.linkUrl} noLink />
            )
          )}
        </div>
      </div>
      <TakeFirstStep />
      <Footer />
    </React.Fragment>
  );
}

export default Partners;
