import React, { Fragment } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { ADMINISTRATION_FEATURES } from '../../helpers/solutions-mock';
import FeatureCell from '../../components/FeatureCell';
import TakeFirstStep from '../../components/TakeFirstStep';
import PartnerLogos from '../../components/PartnerLogos';
import SolutionsSubnav from '../../components/SolutionsSubnav';

function Administration() {
  return (
    <Fragment>
      <Header isShadowed activeLink="solutions" />
      <div className="content-wrap">
        <SolutionsSubnav />
        <section>
          <div className="section-heading solutions-section black">
            <div className="text">
              <h3>
                <img
                  src="/images/solutions-index/1.png"
                  srcSet="/images/solutions-index/1@2x.png 2x,
             /images/solutions-index/1@3x.png 3x"
                  alt="icon"
                />
                Administration
              </h3>
              <h1>Operations are crucial to your dispensary, and you want them to run smoothly.</h1>
              <p>
                THSuite ensures that everyone who helps you do business has the appropriate level of
                functionality and that it seamlessly integrates with your inventory to help you sell
                faster and smarter. And we’ll customize each feature to your dispensary, not the
                other way around.
              </p>
              {/* <button className="btn green">View Features</button> */}
            </div>
            <div className="image">
              <img
                src="/images/solutions-pages/administration/section-image.png"
                srcSet="/images/solutions-pages/administration/section-image@2x.png 2x,
             /images/solutions-pages/administration/section-image@3x.png 3x"
                alt="icon"
              />
            </div>
          </div>
        </section>
        <section>
          <div className="section-heading center black">
            <h1>Our Administration Features:</h1>
          </div>
        </section>
        <div className="features-grid">
          {ADMINISTRATION_FEATURES.map((feature, featureIndex) => (
            <FeatureCell key={`feature-${featureIndex}`} {...feature} />
          ))}
        </div>
      </div>
      <TakeFirstStep />
      <PartnerLogos />
      <Footer />
    </Fragment>
  );
}

export default Administration;
