import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import useClickOutside from '../hooks/useClickOutside';

function Select({ options, onChange, placeholder, label }) {
  const dropdownRef = useRef(null);
  const [value, setValue] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const handleSelect = option => {
    setValue(option);
    onChange && onChange(option);
    setIsOpen(false);
  };
  useClickOutside(dropdownRef, () => setIsOpen(false));
  return (
    <div className="select-wrap" ref={dropdownRef}>
      <label>{label}</label>
      <div className="value" onClick={() => setIsOpen(!isOpen)}>
        {!!value.value && value.label}
        {!value.value && <span className="placeholder">{placeholder}</span>}
        <div className={classNames('chevron', { open: isOpen })} />
      </div>
      <div className={classNames('dropdown', { open: isOpen })}>
        {options.map(option => (
          <div
            key={`option-${option.value}`}
            className={classNames('option', { selected: option.value === value.value })}
            onClick={() => handleSelect(option)}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Select;
