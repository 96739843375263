import React from 'react';

function PartnerLogos() {
  return (
    <section className="partner-logos">
      <a
        href="http://www.amedicanna.com/"
        rel="noopener noreferrer"
        target="_blank"
        className="logo"
      >
        <img
          alt="icon"
          src="/images/logos/amedicanna.png"
          srcSet="/images/logos/amedicanna@2x.png 2x,
       /images/logos/amedicanna@3x.png 3x"
          className="amedicanna"
        />
      </a>
      <a
        href="https://coloradogrowcompany.com/"
        rel="noopener noreferrer"
        target="_blank"
        className="logo"
      >
        <img
          alt="icon"
          src="/images/logos/cogrowco.png"
          srcSet="/images/logos/cogrowco@2x.png 2x,
       /images/logos/cogrowco@3x.png 3x"
          className="cogrowco"
        />
      </a>
      <a
        href="https://www.herbanlegendstowson.com/"
        rel="noopener noreferrer"
        target="_blank"
        className="logo"
      >
        <img
          alt="icon"
          src="/images/logos/herbanlegends.png"
          srcSet="/images/logos/herbanlegends@2x.png 2x,
       /images/logos/herbanlegends@3x.png 3x"
          className="herbanlegends"
        />
      </a>
      <a
        href="https://www.ochitide.com/"
        className="logo"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          alt="icon"
          src="/images/logos/hitide.png"
          srcSet="/images/logos/hitide@2x.png 2x,
       /images/logos/hitide@3x.png 3x"
          className="hitide"
        />
      </a>
    </section>
  );
}

export default PartnerLogos;
